import React, { useState } from "react";
import Agreement from "../../../template/AgreementSample";
import ButtonTag from "../../../reusebale/button";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import { ArrowBigDown } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import BaseUrl from "../../../../constants/BaseUrl";
import MyLoader from "../../../reusebale/myLoader";
import PropertySuccessPopup from "../../../popup/propertySuccess";
import * as LeaseAction from "../../../../store/actions/lease/LeaseAction"
import { GetNotificationMessageByKey } from "../../../../db/NotificationMsgList";
const Overview = ({ formData, tenentInfo, onClose }) => {
  const { loginSuccess } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const [showSuccess, setShowSuccessPopup] = useState(false);
  const { leaseData, leaseUpdate } = useSelector((state) => state.lease)
  const closePopup = () => {
    setShowSuccessPopup(false);
    onClose();
    dispatch(LeaseAction.ChangePropertyCreateSteps(1))
  };

  function calculateTotalAmount(list) {
    if (!Array.isArray(list)) {
      throw new Error("Input must be an array of objects");
    }

    const total = list.reduce((sum, item) => {
      if (typeof item.amount !== "string") {
        throw new Error(
          `Invalid amount format for item: ${JSON.stringify(item)}`
        );
      }
      const amount = parseFloat(item.amount);
      if (isNaN(amount)) {
        throw new Error(
          `Invalid amount value for item: ${JSON.stringify(item)}`
        );
      }
      return sum + amount;
    }, 0);

    return total;
  }
  const [loading, setLoading] = useState(false);

  const CreateNotification = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "landlordId": loginSuccess.user._id,
      "title": GetNotificationMessageByKey("new_lease").title,
      "description": `${GetNotificationMessageByKey("new_lease").description} ${formData?.propertyInfo?.propertyName}`
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/notification/createNotification`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          // ShowSuccess("Notification Created Successfully")
        }
      })
      .catch((error) => console.error(error));
  }

  const CreateNotificationUpdate = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "landlordId": loginSuccess.user._id,
      "title": GetNotificationMessageByKey("new_update").title,
      "description": `${GetNotificationMessageByKey("new_update").description} ${formData?.propertyInfo?.propertyName}`
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/notification/createNotification`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          // ShowSuccess("Notification Created Successfully")
        }
      })
      .catch((error) => console.error(error));
  }

  const CreateLeaseHadnler = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      landlordId: loginSuccess.user._id,
      propertyInfo: formData?.propertyInfo,
      unitInfo: formData.unitInfo,
      leaseTerm: formData.leaseTerm,
      startDate: formData.startDate,
      endDate: formData.endDate,
      tenantInfo: tenentInfo,
      rentStartDate: formData.rentStartDate,
      rentFrequency: formData.rentFrequency,
      chargeLate: formData.chargeLate,
      latePercentage: formData.latePercentage,
      graceDays: formData.graceDays,
      additionalCharges: formData.additionalCharges,
      securityDeposit: formData.securityDeposit,
      lastDayToSubmitDeposit: formData.lastDayToSubmitDeposit,
      securityDepositAmount: formData.securityDepositAmount,
      isEsignature: formData.isEsignature,
      eSignature: formData.eSignature,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}api/lease/createNewLease`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setLoading(false);
          ShowSuccess("Lease created successfully");
          setShowSuccessPopup(true)
          CreateNotification()
        } else {
          setLoading(false);
          ShowError(result.message);
        }
      })
      .catch((error) => console.error(error));
  };


  const onUpdateLeaseHanadler = () => {
    setLoading(true)

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      leaseId: leaseData._id,
      landlordId: loginSuccess.user._id,
      propertyInfo: formData.propertyInfo,
      unitInfo: formData.unitInfo,
      leaseTerm: formData.leaseTerm,
      startDate: formData.startDate,
      endDate: formData.endDate,
      tenantInfo: tenentInfo,
      rentStartDate: formData.rentStartDate,
      rentFrequency: formData.rentFrequency,
      chargeLate: formData.chargeLate,
      latePercentage: formData.latePercentage,
      graceDays: formData.graceDays,
      additionalCharges: formData.additionalCharges,
      securityDeposit: formData.securityDeposit,
      lastDayToSubmitDeposit: formData.lastDayToSubmitDeposit,
      securityDepositAmount: formData.securityDepositAmount,
      isEsignature: formData.isEsignature,
      eSignature: formData.eSignature,
      conversation: leaseData.conversation,
      status: leaseData.inActive,

    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/lease/updateLease`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setLoading(false);
          ShowSuccess("Lease created successfully");
          setShowSuccessPopup(true)
          CreateNotificationUpdate()
        } else {
          setLoading(false);
          ShowError(result.message);
        }
      })
      .catch((error) => console.error(error));
  }

  return (
    <div className="h-full pt-5">
      <div className="h-[90%] w-[95%] mx-auto flex justify-between pb-5">
        <div className="w-[49%]  border border-[#E8E8E8] rounded-md">
          <div className="h-[80%] overflow-y-scroll panelScroll bg-white">
            <Agreement tenentInfo={tenentInfo} data={formData} />
          </div>

          <div className="h-[20%]">
            <div className="bg-[#FAF2EC] flex px-5 h-full items-center rounded-lg">
              <div className="w-[50%] flex items-center">
                <ImageTag
                  path="/assets/daashboard/pdf.png"
                  classes="w-[50px] h-[45px]"
                  altText="logo"
                />
                <div className="pl-2">
                  <ParagraphTag
                    content="Signed Leases 2024.pdf"
                    classes="text-darkColor font-popinsSemiBold text-xs"
                  />
                  <ParagraphTag
                    content="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec."
                    classes="text-darkColor font-popinsMedium text-[10px] line-clamp-2 pt-[2px]"
                  />
                  <div className="flex pt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M3.20915 1.01507C3.20915 0.60703 3.54519 0.246997 3.97723 0.246997C4.38526 0.246997 4.7453 0.60703 4.7453 1.01507V1.78314H7.81758V1.01507C7.81758 0.60703 8.15362 0.246997 8.58566 0.246997C8.99369 0.246997 9.35373 0.60703 9.35373 1.01507V1.78314H10.5058C11.1299 1.78314 11.6579 2.31119 11.6579 2.93525V4.08736H0.90494V2.93525C0.90494 2.31119 1.40899 1.78314 2.05705 1.78314H3.20915V1.01507ZM11.6579 4.85543V11.384C11.6579 12.0321 11.1299 12.5361 10.5058 12.5361H2.05705C1.40899 12.5361 0.90494 12.0321 0.90494 11.384V4.85543H11.6579Z"
                        fill="#FF814E"
                      />
                    </svg>
                    <ParagraphTag
                      content="10/09/2023"
                      classes="text-darkColor/40 font-popinsRegular text-xs pl-1"
                    />
                  </div>
                </div>
              </div>
              <div className="w-[50%] flex justify-end items-center">
                <button className="border-2 border-themeColor flex justify-center items-center px-4 h-9 rounded-lg">
                  <ArrowBigDown color="#0381CA" size={20} />
                  <span className="text-themeColor font-popinsRegular text-xs pl-1">
                    Download Document
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[49%] border border-[#E8E8E8] rounded-lg">
          <div className="h-[85%] w-[90%] mx-auto pt-4">
            <ParagraphTag
              content="Rent Breakdown"
              classes="text-darkColor font-popinsSemiBold text-base text-center pt-1"
            />

            {formData?.additionalCharges?.map((i) => {
              return (
                <div className="flex pt-5 justify-between">
                  <div className="w-[50%]">
                    <ParagraphTag
                      content={i?.title}
                      classes="text-darkColor font-popinsSemiBold text-xs"
                    />
                    <ParagraphTag
                      content={i?.description}
                      classes="text-darkColor/40 font-popinsRegular text-xs line-clamp-1"
                    />
                  </div>
                  <div className="w-[50%] flex justify-end items-center">
                    <ParagraphTag
                      content={`$ ${Number(i.amount).toFixed(2).toString()}`}
                      classes="text-darkColor font-popinsSemiBold text-sm"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="h-[15%] border-t border-darkColor/10 w-[90%] mx-auto">
            {/* <div className="h-[1px] bg-darkColor/10 w-full mt-20"></div> */}
            <div className="flex py-5 justify-between">
              <div className="w-[50%]">
                <ParagraphTag
                  content="Total Amount"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
              <div className="w-[50%] flex justify-end items-center">
                <ParagraphTag
                  content={`$ ${Number(
                    calculateTotalAmount(formData?.additionalCharges)
                  )?.toFixed(2)}`}
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          {loading ? (
            <div className="flex justify-center">
              <MyLoader />
            </div>
          ) : (
            <ButtonTag
              onSubmit={(e) => {
                // isEsignature: formData.isEsignature,
                // eSignature: formData.eSignature,
                e.preventDefault();

                if (!formData?.propertyInfo) {
                  ShowError("Property information is required")
                } else if (!formData.unitInfo) {
                  ShowError("Unit information is required")
                } else if (!formData.leaseTerm) {
                  ShowError("Lease term is required")
                } else if (!formData.startDate) {
                  ShowError("Contract start date is required")
                } else if (!formData.endDate) {
                  ShowError("Contract end date is required")
                } else if (tenentInfo.firstName === "") {
                  ShowError("Tenant first name is required")
                } else if (tenentInfo.lastName === "") {
                  ShowError("Tenant last name is required")
                } else if (tenentInfo.email === "") {
                  ShowError("Tenant email is required")
                } else if (tenentInfo.cellNo === "") {
                  ShowError("Tenant cell number is required")
                } else if (!formData.rentStartDate) {
                  ShowError("Rent start date is required")
                } else if (!formData.rentStartDate) {
                  ShowError("Rent start date is required")
                } else if (formData.chargeLate && (!formData.latePercentage || !formData.graceDays)) {
                  ShowError("Late cahrges information is missing")
                } else if (!formData?.additionalCharges?.length === 0) {
                  ShowError("Rent breakdown is required")
                } else if (formData.securityDeposit && (!formData.lastDayToSubmitDeposit || !formData.securityDepositAmount)) {
                  ShowError("Deposit information is missing")
                } else if (formData.isEsignature && (!formData.eSignature)) {
                  ShowError("E-Signature is missing")
                } else {
                  if (leaseUpdate) {
                    onUpdateLeaseHanadler()
                  } else {
                    CreateLeaseHadnler()
                  }

                }


              }}
              name={leaseUpdate ? "Updare" : "Submit"}
              classes="text-base text-center bg-themeColor text-white"
            />
          )}
        </div>
      </div>
      {showSuccess && <PropertySuccessPopup succMessage={`${leaseUpdate ? "Lease updated" : "New lease created"} successfully.`} onClose={closePopup} />}
    </div>
  );
};

export default Overview;
