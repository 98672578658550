import React, { useState } from "react";
import SearchBar from "../../reusebale/searchBar"
import TutorialSection from "./tutorialSection"

const HelpSection = () => {
  const [search, setSearch] = useState("")
  return (
    <div className="h-screen">
      <div className="h-[10vh] mr-2">
        <SearchBar
          value={search}
          onSearchChange={(e) => {
            setSearch(e.target.value)
          }}
          title='Help & Tutorials' desc='All tutorials are listed below for your guidance.' />
      </div>
      <div className="h-[90vh]">
        <TutorialSection search={search} />
      </div>
    </div>
  );
};

export default HelpSection;
