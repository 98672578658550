import React, { useEffect, useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import * as PropertyAction from "../../../../store/actions/property/PropertyAction";
import BaseUrl from "../../../../constants/BaseUrl";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import { useDispatch, useSelector } from "react-redux";
import MyLoader from "../../../reusebale/myLoader";
import db from "../../../../db/index.json";
import UnitsCard from "../../../reusebale/uniCard";
import EditUnits from "../../../popup/editUnits";
import { AlignRight, Plus } from "lucide-react";
import WarningPopup from "../../../popup/warning"
const PropertyUnits = () => {
  const { properties } = db;
  const dispatch = useDispatch();
  const { createNewPropertyBody, isUpdate } = useSelector((state) => state.property);
  const [isHVAC, setIsHVAC] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleCheckboxChange = () => {
    setIsHVAC((prevState) => !prevState);
    setUnitFormFields({
      ...unitFormFields,
      hvac: !unitFormFields.hvac,
    });
  };

  const openPopup = () => {
    setIsEdit(true);
  };

  const closePopup = () => {
    setIsEdit(false);
  };



  const [warning, setWarning] = useState(false)
  const [unitId, setUnitId] = useState()

  const [unitFormFields, setUnitFormFields] = useState({
    numberOfUnits: 0,
    totalBuilding: 0,
    rent: 0,
    rentType: "Monthly",
    offices: 0,
    bathrooms: 0,
    size: 0,
    buildingDepth: 0,
    clearHeight: 0,
    parkingRation: 0,
    sprinklers: 0,
    water: 0,
    electric: 0,
    waltRollArea: 0,
    numberOfSuites: 0,
    lodingDocks: 0,
    gradeLevelDoors: 0,
    tenantSize: 0,
    yearBuild: 0,
    hvac: false,
    hvacValue: 0,
    unitList: [],
  });



  function GenerateUnits(count) {
    const units = [];
    for (let i = 1; i <= count; i++) {
      units.push({
        id: i,
        unitName: `Unit ${i}`,
        totalBuilding: unitFormFields.totalBuilding,
        rent: unitFormFields.rent,
        rentType: unitFormFields.rentType,
        offices: unitFormFields.offices,
        bathrooms: unitFormFields.bathrooms,
        size: unitFormFields.size,
        buildingDepth: unitFormFields.buildingDepth,
        clearHeight: unitFormFields.clearHeight,
        parkingRation: unitFormFields.parkingRation,
        sprinklers: unitFormFields.sprinklers,
        water: unitFormFields.water,
        electric: unitFormFields.electric,
        waltRollArea: unitFormFields.waltRollArea,
        numberOfSuites: unitFormFields.numberOfSuites,
        lodingDocks: unitFormFields.lodingDocks,
        gradeLevelDoors: unitFormFields.gradeLevelDoors,
        tenantSize: unitFormFields.tenantSize,
        yearBuild: unitFormFields.yearBuild,
        hvac: unitFormFields.hvac,
        hvacValue: unitFormFields.hvacValue,
      });
    }
    ShowSuccess("Unit generated successfully");
    return units;
  }

  const GenerateUnitsHandler = (e) => {
    e.preventDefault();
    if (unitFormFields.numberOfUnits === 0 || !unitFormFields.numberOfUnits) {
      ShowError("No. of units is required")
    } else if (unitFormFields.rent === 0 || !unitFormFields.rent) {
      ShowError("Rent cannot be zero")
    } else if (unitFormFields.size === 0 || !unitFormFields.size) {
      ShowError("Size cannot be zero")
    } else if (showInput[0].value && (unitFormFields.totalBuilding == 0 || !unitFormFields.totalBuilding)) {
      ShowError("Number of buildings are required")

    } else if (showInput[6].value && (unitFormFields.buildingDepth == 0 || !unitFormFields.buildingDepth)) {
      ShowError("Building depth is required")

    } else if (showInput[7].value && (unitFormFields.clearHeight == 0 || !unitFormFields.clearHeight)) {
      ShowError("Clear heigh is required")
    } else if (showInput[8].value && (unitFormFields.parkingRation == 0 || !unitFormFields.parkingRation)) {
      ShowError("Parking ration is required")
    } else if (showInput[9].value && (unitFormFields.sprinklers == 0 || !unitFormFields.sprinklers)) {
      ShowError("Sprinklers ration is required")
    } else if (showInput[10].value && (unitFormFields.water == 0 || !unitFormFields.water)) {
      ShowError("Water is required")
    } else if (showInput[11].value && (unitFormFields.electric == 0 || !unitFormFields.electric)) {
      ShowError("Electric is required")
    } else if (showInput[12].value && (unitFormFields.numberOfSuites == 0 || !unitFormFields.numberOfSuites)) {
      ShowError("Suits is required")
    } else if (showInput[13].value && (unitFormFields.lodingDocks == 0 || !unitFormFields.lodingDocks)) {
      ShowError("Loading docks is required")
    } else if (showInput[14].value && (unitFormFields.gradeLevelDoors == 0 || !unitFormFields.gradeLevelDoors)) {
      ShowError("Grade doors is required")
    } else if (showInput[15].value && (unitFormFields.tenantSize == 0 || !unitFormFields.tenantSize)) {
      ShowError("Tenant size is required")
    } else if (showInput[16].value && (unitFormFields.yearBuild == 0 || !unitFormFields.yearBuild)) {
      ShowError("Year build is required")
    } else if (showInput[17].value && (unitFormFields.hvacValue == 0 || !unitFormFields.hvacValue)) {
      ShowError("HVAC is required")
    } else {
      setUnitFormFields({
        ...unitFormFields,
        unitList: GenerateUnits(unitFormFields.numberOfUnits),
      });
    }



  };

  useEffect(() => {
    if (createNewPropertyBody.unitCreationFields) {

      const updatedUnitCreationFields = {
        ...createNewPropertyBody.unitCreationFields,
        unitList: createNewPropertyBody.unitData,
      };
      setUnitFormFields(updatedUnitCreationFields);
    }
  }, []);


  const NextHandler = (e) => {
    const { unitList, ...rest } = unitFormFields;


    e.preventDefault();
    if (unitFormFields?.unitList?.length === 0) {
      ShowError("Please crate units first");
    } else {
      dispatch(PropertyAction.ChangePropertyCreateSteps(4));
      dispatch(
        PropertyAction.CreatePropertyStepThreeAction(
          rest,
          unitFormFields.unitList
        )
      );
    }

    // alert(JSON.stringify(createNewPropertyBody))
  };
  const [showInput, setShowInput] = useState([
    {
      name: "Buildings",
      value: false,
    },

    {
      name: "Rent",
      value: true,
    },

    {
      name: "Rent Type",
      value: true,
    },

    {
      name: "Offices",
      value: true,
    },
    {
      name: "Bathrooms",
      value: true,
    },

    {
      name: "Size",
      value: true,
    },

    {
      name: "Building Depth",
      value: false,
    },
    {
      name: "Clear Height",
      value: false,
    },

    {
      name: "Parking Ratio",
      value: false,
    },
    {
      name: "Sprinklers",
      value: false,
    },
    {
      name: "Water",
      value: false,
    },
    {
      name: "Electric",
      value: false,
    },

    {
      name: "Suites",
      value: false,
    },
    {
      name: "Loading Docks",
      value: false,
    },

    {
      name: "Grade Doors",
      value: false,
    },

    {
      name: "Tenant Size",
      value: true,
    },

    {
      name: "Year Build",
      value: false,
    },

    {
      name: "HVAC",
      value: false,
    },
  ]);
  const PreCheckOnUdate = () => {
    let updatedList = [...showInput]
    for (const key in updatedList) {
      if (updatedList[key].name === "Buildings") {
        if (createNewPropertyBody?.unitCreationFields?.totalBuilding !== 0) {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "HVAC") {
        if (createNewPropertyBody.unitCreationFields.hvacValue !== 0 && createNewPropertyBody.unitCreationFields.hvacValue !== "") {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "Building Depth") {
        if (createNewPropertyBody.unitCreationFields.buildingDepth !== 0) {
          updatedList[key].value = true
        }
      }


      if (updatedList[key].name === "Clear Height") {
        if (createNewPropertyBody.unitCreationFields.clearHeight !== 0) {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "Parking Ratio") {
        if (createNewPropertyBody.unitCreationFields.parkingRation !== 0) {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "Sprinklers") {
        if (createNewPropertyBody.unitCreationFields.sprinklers !== 0) {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "Water") {
        if (createNewPropertyBody.unitCreationFields.water !== 0) {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "Electric") {
        if (createNewPropertyBody.unitCreationFields.electric !== 0) {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "Suites") {
        if (createNewPropertyBody.unitCreationFields.numberOfSuites !== 0) {
          updatedList[key].value = true
        }
      }


      if (updatedList[key].name === "Loading Docks") {
        if (createNewPropertyBody.unitCreationFields.lodingDocks !== 0) {
          updatedList[key].value = true
        }
      }


      if (updatedList[key].name === "Grade Doors") {
        if (createNewPropertyBody.unitCreationFields.gradeLevelDoors !== 0) {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "Tenant Size") {
        if (createNewPropertyBody.unitCreationFields.tenantSize !== 0) {
          updatedList[key].value = true
        }
      }

      if (updatedList[key].name === "Year Build") {
        if (createNewPropertyBody.unitCreationFields.yearBuild !== 0) {
          updatedList[key].value = true
        }
      }




    }
    setShowInput(updatedList)
  }

  useEffect(() => {
    if (isUpdate) {
      PreCheckOnUdate()
      setUnitFormFields(createNewPropertyBody.unitCreationFields)
      const updatedUnitCreationFields = {
        ...createNewPropertyBody.unitCreationFields,
        unitList: createNewPropertyBody.unitData,
      };
      setUnitFormFields(updatedUnitCreationFields);
    }


  }, [isUpdate, createNewPropertyBody])

  const handleInputShow = (index, item) => {
    if (
      item === "Size" ||
      item === "Rent" ||
      item === "Rent Type" ||
      item === "Bathrooms" ||
      item === "Offices" ||
      item === "Tenant Size"
    ) {
      ShowError(`${item} is required`);
      return;
    }
    const updatedInputs = [...showInput];
    updatedInputs[index].value = !updatedInputs[index].value; // Value ko toggle karte hain
    setShowInput(updatedInputs);
  };

  const [selectedUnit, setSelectedUnit] = useState({
    id: 0,
    unitName: "",
    totalBuilding: 0,
    rent: 0,
    rentType: 0,
    offices: 0,
    bathrooms: 0,
    size: 0,
    buildingDepth: 0,
    clearHeight: 0,
    parkingRation: 0,
    sprinklers: 0,
    water: 0,
    electric: 0,
    waltRollArea: 0,
    numberOfSuites: 0,
    lodingDocks: 0,
    gradeLevelDoors: 0,
    tenantSize: 0,
    yearBuild: 0,
    hvac: false,
    hvacValue: 0,
  });

  function ReplaceSpecificUnit(list, newItem) {
    if (!Array.isArray(list)) {
      throw new Error("The first argument must be an array.");
    }
    if (!newItem || !newItem.id) {
      throw new Error("The newItem must be an object with an id property.");
    }

    return list.map((item) => (item.id === newItem.id ? newItem : item));
  }

  const onSaveHandler = (e) => {
    e.preventDefault();
    setUnitFormFields({
      ...unitFormFields,
      unitList: ReplaceSpecificUnit(unitFormFields.unitList, selectedUnit),
    });
    ShowSuccess("Unit item updated Successfully");
    setIsEdit(false);
  };

  useEffect(() => {
    if (isUpdate) {

    }
  }, [])

  return (
    <div className="w-full h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Let’s Add The Units For The Property"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <div
          onClick={() => {

            const updatedUnitCreationFields = {
              ...createNewPropertyBody.unitCreationFields,
              unitList: createNewPropertyBody.unitData,
            };
            setUnitFormFields(updatedUnitCreationFields);

          }}
        >
          <ParagraphTag
            content="Add your units"
            classes="text-[#686868] font-popinsRegular text-xs"
          />
        </div>
      </div>
      <div className="h-[80%] w-full overflow-y-scroll panelScroll">
        <div className="w-[85%] mx-auto pb-8">
          <div className="flex flex-wrap">
            {showInput.map((item, index) => (
              <div className="flex items-center w-[20%] mt-2">
                <input
                  type="checkbox"
                  className="w-4 bg-bgColor rounded-sm cursor-pointer"
                  checked={item.value}
                  onChange={() => handleInputShow(index, item.name)}
                />
                <ParagraphTag
                  content={item.name}
                  classes="text-darkColor text-sm pl-2 font-"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex w-[95%] mx-auto justify-between h-full pt-2">
          <div className="w-[49%] overflow-y-scroll h-full panelScroll px-1 pb-2 ">
            <form className="w-full">
              <div className="flex flex-wrap justify-between w-full">
                <div className="w-full">
                  <LabelTag
                    isStaric={false}
                    name="No. of Units"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.numberOfUnits}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        numberOfUnits: e.target.value,
                      });
                    }}
                    type="number"
                    placeholder="Type here"
                    className="appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none w-full mt-1 text-sm bg-bgColor font-popinsRegular py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="w-[49%]">
                  <LabelTag
                    isStaric={false}
                    name="Rent"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.rent}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        rent: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[49%]">
                  <LabelTag
                    isStaric={false}
                    name="Rent Type"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <select
                    value={unitFormFields.rentType}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        rentType: e.target.value,
                      });
                    }}
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  >
                    <option>Yearly</option>
                    <option>Monthly</option>
                    <option>Quaterly</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between flex-wrap mt-2"></div>
              <div className="flex flex-wrap mt-2" id="gk-cards">
                {showInput[0].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Total buildings in the park"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.totalBuilding}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          totalBuilding: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
                {showInput[3].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Offices"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.offices}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          offices: e.target.value,
                        });
                      }}
                      type="number"
                      placeholder="Type here"
                      className="appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
                <div className="w-[32%] mr-[2%]">
                  <LabelTag
                    isStaric={false}
                    name="Bathrooms"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.bathrooms}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        bathrooms: e.target.value,
                      });
                    }}
                    type="number"
                    placeholder="Type here"
                    className="appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%] mr-[2%]">
                  <LabelTag
                    isStaric={false}
                    name="Size (sqft)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.size}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        size: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Type here"
                    className="appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                {showInput[6].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Building depth (%)"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.buildingDepth}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          buildingDepth: e.target.value,
                        });
                      }}
                      type="number"
                      placeholder="Type here"
                      className="appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
                {showInput[7].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Clear height (%)"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.clearHeight}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          clearHeight: e.target.value,
                        });
                      }}
                      type="number"
                      placeholder="Type here"
                      className="appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}

                {showInput[8].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Parking Ratio"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.parkingRation}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          parkingRation: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
                {showInput[9].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Sprinklers"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.sprinklers}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          sprinklers: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}

                {showInput[10].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Water"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.water}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          water: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}

                {showInput[11].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Electric (%)"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.electric}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          electric: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}

                {showInput[12].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="# of Suites (%)"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.numberOfSuites}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          numberOfSuites: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
                {showInput[13].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Loading docks #"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.lodingDocks}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          lodingDocks: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
                {showInput[14].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Grade level doors"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.gradeLevelDoors}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          gradeLevelDoors: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}

                {showInput[15].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Tenant size (Sqft)"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.tenantSize}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          tenantSize: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
                {showInput[16].value && (
                  <div className="w-[32%] mr-[2%]">
                    <LabelTag
                      isStaric={false}
                      name="Year built"
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.yearBuild}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          yearBuild: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}

                {showInput[17].value && (
                  <div className="w-[32%]">
                    <LabelTag
                      isStaric={false}
                      name="HVAC "
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.hvacValue}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          hvacValue: e.target.value,
                        });
                      }}
                      type="number"
                      placeholder="Type here"
                      className="appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="w-[2%] flex justify-center">
            <div className="h-full w-[1px] bg-black/20"></div>
          </div>
          <div className="w-[49%] overflow-y-scroll h-full panelScroll">
            {unitFormFields?.unitList?.map((item, i) => (
              <UnitsCard
              isDeleteShow={true}
                onDelete={(item) => {
                  setUnitId(item.id)
                  setWarning(true)


                }}
                data={item}
                classes="w-full my-2 py-3 px-2 cursor-pointer"
                onTrigger={() => {
                  setSelectedUnit(item);
                  openPopup();
                }}
                isDelete={true}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%] flex justify-center items-center">
          {
            !isUpdate && <button
              onClick={GenerateUnitsHandler}
              className="font-popinsMedium py-2 flex justify-center items-center w-[30%] text-sm border border-themeColor text-themeColor rounded-md"
            >
              <Plus color="#0381CA" size={18} />
              <span className="pl-2">Generate units</span>
            </button>
          }

        </div>
        <div className="w-[50%] mx-auto flex justify-center items-center">
          <ButtonTag
            onSubmit={NextHandler}
            name="Next"
            classes="text-base text-center bg-themeColor text-white !w-[80%]"
          />
        </div>
      </div>
      {isEdit && (
        <EditUnits
          selectedUnit={selectedUnit}
          onSave={onSaveHandler}
          onBack={closePopup}
          unitNameValue={selectedUnit.unitName}
          buildingValue={selectedUnit?.totalBuilding}
          rentValue={selectedUnit?.rent}
          rentTypeValue={selectedUnit.rentType}
          officesValue={selectedUnit.offices}
          bathroomsValue={selectedUnit.bathrooms}
          sizeValue={selectedUnit.size}
          buildingDepthValue={selectedUnit.buildingDepth}
          clearHeightValue={selectedUnit.clearHeight}
          parkingRationValue={selectedUnit.parkingRation}
          sprinklersValue={selectedUnit.sprinklers}
          waterValue={selectedUnit.water}
          electricValue={selectedUnit.electric}
          waltRollAreaValue={selectedUnit.waltRollArea}
          noOfSuitsValue={selectedUnit.numberOfSuites}
          loadingDocksValue={selectedUnit.lodingDocks}
          gradeLevelDoorsValue={selectedUnit.gradeLevelDoors}
          yearBuildValue={selectedUnit.yearBuild}
          tenantsSizeValue={selectedUnit.tenantSize}
          hvacValue={selectedUnit.hvac}
          hvacNumberValue={selectedUnit.hvacValue}
          unitNameValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              unitName: e.target.value,
            });
          }}
          buildingValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              totalBuilding: e.target.value,
            });
          }}
          rentValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              rent: e.target.value,
            });
          }}
          renttypeValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              rentType: e.target.value,
            });
          }}
          officesValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              offices: e.target.value,
            });
          }}
          bathroomsValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              bathrooms: e.target.value,
            });
          }}
          sizeValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              size: e.target.value,
            });
          }}
          buildingDepthChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              buildingDepth: e.target.value,
            });
          }}
          clearHeighValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              clearHeight: e.target.value,
            });
          }}
          parkingRationValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              parkingRation: e.target.value,
            });
          }}
          sprinklersValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              sprinklers: e.target.value,
            });
          }}
          waterValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              water: e.target.value,
            });
          }}
          electricValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              electric: e.target.value,
            });
          }}
          waltRollAreaValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              waltRollArea: e.target.value,
            });
          }}
          noOfSuitsValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              numberOfSuites: e.target.value,
            });
          }}
          loadingDocksValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              lodingDocks: e.target.value,
            });
          }}
          gradeLevelDoorsValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              gradeLevelDoors: e.target.value,
            });
          }}
          tenantsSizeValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              tenantSize: e.target.value,
            });
          }}
          yearBuildValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              yearBuild: e.target.value,
            });
          }}
          hvacValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              hvac: !selectedUnit.hvac,
            });
          }}
          hvacNumberValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              hvacValue: e.target.value,
            });
          }}
        />
      )}
      {
        warning && <WarningPopup
          onDeleteItem={() => {
            let list = [...unitFormFields.unitList]
            const filterList = list.filter((i) => i.id !== unitId)
            setUnitFormFields({
              ...unitFormFields,
              unitList: filterList
            })
            setWarning(false)
            setUnitId()

          }}
          onClose={() => {
            setWarning(false)
          }}

        />
      }

    </div>
  );
};

export default PropertyUnits;
