

const LeaseLoader = () => {
  return (
    <div className="p-4 bg-gray-100 rounded-lg animate-pulse flex justify-between">
      <div className="w-[59%]">
        {/* Property Information Skeleton */}
        <div className="bg-white p-6 rounded-lg shadow-md flex">
          <div className="w-[30%]">
            <div className="bg-gray-200 rounded-full w-[150px] h-[150px] mb-4"></div>
          </div>
          <div className="w-[70%] flex flex-col justify-center">
            <div className="h-6 bg-gray-200 rounded w-2/3 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-4/6"></div>
          </div>
        </div>

        {/* Rental Property Lease Agreement Skeleton */}
        <div className="mt-6 bg-white p-6 rounded-lg shadow-md">
          <div className="h-6 bg-gray-200 rounded w-2/3 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
          <div className="h-10 bg-gray-200 rounded w-full mb-2"></div>
          <div className="h-4 bg-gray-200 rounded w-4/6"></div>
          <div className="h-16 bg-gray-200 rounded w-full mt-5"></div>
        </div>

        {/* Chat Skeleton */}
        <div className="mt-6 bg-white p-4 rounded-lg shadow-md">
          <div className="h-6 bg-gray-200 rounded w-full mb-4"></div>
          <div className="flex justify-between">
            <div className="h-6 bg-gray-200 rounded w-[200px] mb-4"></div>
            <div className="h-6 bg-gray-200 rounded w-[200px] mb-4"></div>
          </div>
          <div className="flex justify-between">
            <div className="h-6 bg-gray-200 rounded w-[200px] mb-4"></div>
            <div className="h-6 bg-gray-200 rounded w-[200px] mb-4"></div>
          </div>
          <div className="flex justify-between">
            <div className="h-6 bg-gray-200 rounded w-[200px] mb-4"></div>
            <div className="h-6 bg-gray-200 rounded w-[200px] mb-4"></div>
          </div>
          <div className="h-10 bg-gray-200 rounded"></div>
        </div>
      </div>
      <div className="w-[39%] h-full">
        <div className=" bg-white p-6 rounded-lg shadow-md h-[620px]">
          <div className="h-6 bg-gray-200 rounded w-full mb-4"></div>

          <div className="h-8 bg-gray-200 rounded w-[200px] mb-2 mt-10"></div>
          <div className="flex justify-end mt-5">
            <div className="h-8 bg-gray-200 rounded w-[200px]"></div>
          </div>
          <div className="h-8 bg-gray-200 rounded w-[200px] mb-2 mt-5"></div>
          <div className="flex justify-end mt-5">
            <div className="h-8 bg-gray-200 rounded w-[200px]"></div>
          </div>
          <div className="h-8 bg-gray-200 rounded w-[200px] mb-2 mt-5"></div>
          <div className="flex justify-end mt-5">
            <div className="h-8 bg-gray-200 rounded w-[200px]"></div>
          </div>
          <div className="h-8 bg-gray-200 rounded w-[200px] mb-2 mt-5"></div>
          <div className="flex justify-end mt-5">
            <div className="h-8 bg-gray-200 rounded w-[200px]"></div>
          </div>
          <div className="h-16 bg-gray-200 rounded w-full mt-12"></div>
        </div>
      </div>
    </div>
  );
};

export default LeaseLoader;
