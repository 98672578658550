import React, { useState } from "react";
import SearchBar from "../../reusebale/searchBar";
import BroadCastSection from "./broadCastSection";
import CreateBroadCast from "./createBroadCast/main";
import BroadCastDetail from "./broadcastDetail/main";

import db from "../../../db/index.json";

// onClick={onBack}

const BroadCast = ({ onBack, onBackHome }) => {
  const { broadcasts } = db;
  const [showTable, setShowTable] = useState("table");
  const [detail, setDetails] = useState(null)

  const toggleDetail = (data) => {
    setDetails(data)
    setShowTable("detail"); // State toggle karein
  };

  const toggleCreate = () => {
    setShowTable("create"); // State toggle karein
  };

  const toggleTable = () => {
    setShowTable("table"); // State toggle karein
  };
  const [search, setSearch] = useState("")

  return (
    <div>
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div className="h-screen">
                <div className="h-[10vh]">
                  <SearchBar
                    value={search}
                    onSearchChange={(e) => {
                      setSearch(e.target.value)

                    }}
                    title={broadcasts.title}
                    desc={broadcasts.subTitle}
                  />
                </div>
                <div className="h-[90vh]">
                  <BroadCastSection
                    search={search}
                    onViewDetails={(data) => {
                      toggleDetail(data)
                    }}
                    onViewCreate={toggleCreate}
                  />
                </div>
              </div>
            );
          case "detail":
            return <BroadCastDetail detail={detail} onBack={toggleTable} />;
          case "create":
            return <CreateBroadCast onBackHome={onBackHome} />;
          default:
            break;
        }
      })()}
    </div>
  );
};

export default BroadCast;
