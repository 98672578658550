import React, { useState } from "react";
import SearchBar from "../../reusebale/searchBar";
import db from "../../../db/index.json";
import PropertyTable from "../properties/propertiesTable";
import MainSection from "./property-detail/main";
import CreateProperty from "./create-property/main";
import FilterPopup from "../../popup/filterPopup";
import DetailMain from "../maintenance/maintanance-detail/detailMain";
import CreateMain from "../leases/createProperty/createMain";
import DetailLeaseMain from "../leases/leaseDetail/main";
import { useDispatch, useSelector } from "react-redux";
import * as PropertyAction from "../../../store/actions/property/PropertyAction";
import * as LeaseDataAction from "../../../store/actions/lease/LeaseAction"

const PropertySection = ({ toggleMaintainanceTable, onBackHome }) => {
  const { properties } = db;
  const dispatch = useDispatch();
  const { loader, createNewPropertyBody } = useSelector(
    (state) => state.property
  );

  const [showTable, setShowTable] = useState("table"); // Default: Table show ho raha hai

  const [showFilter, setShowFilter] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showDetail, setShowDetail] = useState("");
  const [recordLeaseId, setLeaseRecordId] = useState();

  const [propertyId, setPropertyId] = useState();

  const openPopup = () => {
    setShowFilter(true);
  };

  const closePopup = () => {
    setShowFilter(false);
  };

  const toggleDetail = (id) => {
    setPropertyId(id);
    localStorage.setItem("propertid", id);
    setShowTable("detail"); // State toggle karein
  };

  const toggleDetailProperty = () => {
    const savedUserName = localStorage.getItem("propertid");
    setPropertyId(savedUserName);
    setShowTable("detail");
  };

  const toggleCreate = () => {
    dispatch(PropertyAction.ClearAllPrevStateAction());
    setShowTable("create"); // State toggle karein
  };

  const toggleUpdate = (id) => {
    dispatch(PropertyAction.ClearAllPrevStateAction());
    dispatch(PropertyAction.GetPropertyDetailsAction(id));

    // alert(id)

    setShowTable("create"); // State toggle karein
  };

  const toggleTable = () => {
    setShowTable("table"); // State toggle karein
  };

  const handleCreateLease = () => {
    setShowTable("createLease"); // State toggle karein
  };

  const toggleDetailMaintain = () => {
    setShowTable("MaintainanceDetails");
  };

  const toggleLeaseDetail = (status, id) => {
    setShowDetail(status);
    setLeaseRecordId(id);
    setShowTable("detailLease"); // State toggle karein
  };

  const [search, setSearch] = useState("")

  return (
    <div>
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div className="h-screen w-full">
                <div className="h-[10vh] mr-2">
                  <SearchBar
                    value={search}
                    onSearchChange={(e) => {
                      setSearch(e.target.value)

                    }}
                    title={properties.title}
                    desc={properties.desc}
                    onSubmit={openPopup}
                  />
                </div>
                <div className="h-[90vh]">
                  <PropertyTable
                    search={search}
                    onViewDetails={(id) => {
                      toggleDetail(id);
                    }}
                    onViewCreate={toggleCreate}
                    onViewUpdate={toggleUpdate}
                  />
                </div>
              </div>
            );
          case "detail":
            return (
              <MainSection
                propertyId={propertyId}
                onBack={toggleTable}
                onViewDetailsMaintain={(item) => {
                  setRecordId(item);
                  toggleDetailMaintain(item);
                }}
                onMaintainancePage={toggleMaintainanceTable}
                onCreateLease={handleCreateLease}
                toggleLeaseDetail={toggleLeaseDetail}
              />
            );
          case "create":
            return <CreateProperty onBack={toggleTable} />;
          case "MaintainanceDetails":
            return (
              <DetailMain
                recordId={recordId._id}
                onBack={toggleDetailProperty}
              />
            );
          case "createLease":
            return <CreateMain onBack={onBackHome} />;
          case "detailLease":
            return (
              <DetailLeaseMain
                onBack={toggleDetailProperty}
                showDetailStatus={showDetail}
                recordId={recordLeaseId}
                onViewDetailsMaintain={(item) => {
                  setRecordId(item);
                  toggleDetailMaintain(item);
                }}
                onEditLease={(leaseDetails) => {
                  handleCreateLease();
                  dispatch(LeaseDataAction.LeaseDetailAction(leaseDetails));
                }}
                onMaintainancePage={toggleMaintainanceTable}
              />
            );
          default:
            break;
        }
      })()}
      {showFilter && <FilterPopup onBack={closePopup} onFilter={closePopup} />}
    </div>
  );
};

export default PropertySection;
