import React from "react";


const LogoutSection = () => {


  return (
    <div>right</div>


    
  );
};

export default LogoutSection;
