import React, { useState, useEffect } from "react";
import ParagraphTag from "../reusebale/Paragraph";
import LabelTag from "../reusebale/label";
import ButtonTag from '../reusebale/button';
import BaseUrl from "../../constants/BaseUrl";
import { useSelector } from "react-redux"
const FilterPopup = ({ onFilter, onBack }) => {
  const { loader, loginSuccess, loginError } = useSelector(
    (state) => state.auth
  );

  const [propertyName, setPropertyName] = useState([])
  const [leaseStatus, setLeaseStatus] = useState([])
  const [selectedPropertyName, setSelectedPropertyName] = useState("")
  const [selectedLeaseStatus, setSelectedLeaseStatus] = useState("")
  const GetAllPropertyList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/property/getPropertiesNameByLandlord/${loginSuccess?.user?._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          let property = [...result.data]
          let allObject = { _id: Math.random(), propertyName: "all" };
          property.unshift(allObject)
          setPropertyName(property)
          setSelectedPropertyName(result?.data[0]?.propertyName ? result?.data[0]?.propertyName : "")
          setSelectedLeaseStatus(result?.leaseStatuses[0]?.statusName ? result?.leaseStatuses[0]?.statusName : "")
          setLeaseStatus(result.leaseStatuses)

        }
      })
      .catch((error) => console.error(error));

  }



  useEffect(() => {
    GetAllPropertyList()
  }, [])

  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[18%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col">
          <div>
            <ParagraphTag
              content="Filter"
              classes={`text-darkColor font-popinsSemiBold text-sm `}
            />
          </div>
          <div className="w-full px-2 mt-2">
            <LabelTag isStaric={false} name="Property" />
            <select onChange={(e) => {
              if (e.target.value === "all") {
                setSelectedPropertyName("")
              } else {
                setSelectedPropertyName(e.target.value)
              }

            }} value={selectedPropertyName} className="w-full mt-2 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor/40 placeholder:text-zinc-700/50 font-popinsRegular">
              {
                propertyName.map((i) => {
                  return (
                    <option value={i?.propertyName}>{i?.propertyName}</option>
                  )
                })
              }


            </select>

          </div>
          {/* <div className="w-full px-2 mt-2">
            <LabelTag isStaric={false} name="Unit" />
            <select value='' className="w-full mt-2 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor/40 placeholder:text-zinc-700/50 font-popinsRegular">
              <option value="Select1">Select 1</option>
              <option value="Select2">Select 2</option>
              <option value="Select3">Select 3</option>
            </select>

          </div> */}
          <div className="w-full px-2 mt-2">
            <LabelTag isStaric={false} name="Statuses" />
            <select onChange={(e) => {
              setSelectedLeaseStatus(e.target.value)
            }} value={selectedLeaseStatus} className="w-full mt-2 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor/40 placeholder:text-zinc-700/50 font-popinsRegular">
              {
                leaseStatus.map((i) => {
                  return (
                    <option value={i?.statusName}>{i?.statusName}</option>
                  )
                })
              }


            </select>

          </div>
          <div className="w-full px-2">
            <ButtonTag onSubmit={() => {
              onFilter(selectedPropertyName, selectedLeaseStatus)
            }} name='Filter' classes='text-sm bg-themeColor hover:bg-themeColor/90 mt-5 text-center  text-white' />
            <p className="text-darkColor pt-2 flex item-center justify-center cursor-pointer font-popinsMedium text-sm" onClick={onBack}>Close</p>
          </div>

        </div>
      </section>
    </main>
  );
};

export default FilterPopup;
