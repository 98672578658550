import React, { useState, useEffect } from "react";
import db from "../../../db/index.json";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import ReactPaginate from "react-paginate";
import NoData from "../../reusebale/noData";
import Myloader from "../../reusebale/myLoader"
import {
  ArrowUpDown,
  ArrowDownUp,
  Plus,
  SquareCheck,
  SquareX,
  CircleHelp,
} from "lucide-react";
import BaseUrl from "../../../constants/BaseUrl";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as LeaseAction from "../../../store/actions/lease/LeaseAction"
import { ShowError } from "../../../utils/ToasFunction";
import LeaseTableLoader from "../../skeleton-loader/lease-table"

const LeasesTable = ({ isViewDetails, onViewCreate, search, status }) => {
  const { lease } = db;
  const { loginSuccess } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const [data, setData] = useState([
    {
      id: 1,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 2,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 3,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "notapprove",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 4,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 5,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 6,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 7,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 8,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 9,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 10,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 11,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 12,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 13,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 14,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 15,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 16,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
  ]);

  const [AllLeases, setAllLeases] = useState([]);

  const GetAllLease = () => {
    setAllLeases([])
    setLoading(true)
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/lease/getAllLeaseByLanlordId/${loginSuccess?.user?._id}?status=${status}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllLeases(result.data);
          setLoading(false)
        } else {
          ShowError(result.message)
          setLoading(false)
          setAllLeases([])
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    GetAllLease();
  }, [status]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const paginatedData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const onViewDetails = (status, id) => {
    isViewDetails(status, id);
  };

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  function CalculateDaysBetween(date1, date2) {
    // Parse the dates using Moment.js
    const startDate = moment(date1);
    const endDate = moment(date2);

    // Calculate the difference in days
    const daysDifference = endDate.diff(startDate, "days");

    return daysDifference;
  }

  return (
    <div className="h-full">
      <div className="bg-white w-full h-[87%]">
        <div className="w-[95%] mx-auto h-full">
          <div className="flex justify-between items-center h-[12%]">
            <div className="w-[70%]">
              <ParagraphTag
                content={lease.heading}
                classes="text-darkColor font-popinsSemiBold text-lg"
              />
            </div>

            <div className="w-[30%] flex justify-end">
              <button
                className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2"
                onClick={() => {
                  dispatch(LeaseAction.ClearAllLeaseDetailsAction())
                  onViewCreate()
                }}
              >
                <Plus color="#FFFFFF" size={15} />
                <ParagraphTag
                  content={lease.new}
                  classes="text-white font-popinsSemiBold text-xs pl-2"
                />
              </button>
            </div>
          </div>
          <div className="overflow-x-auto w-full h-[88%] panelScroll mt-1">
            <table className="w-full ">
              <thead className="sticky top-0 bg-white">
                <tr className="border-b border-black/20">
                  <th className="w-[20%] text-left pb-2">
                    <span className="flex">
                      <ParagraphTag
                        content={lease.heading}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "lease" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("lease")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("lease")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("property")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[8%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={lease.units}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "units" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("units")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("units")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("units")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[8%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={lease.status}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "status" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      )}
                    </span>
                  </th>

                  <th className="w-[20%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={lease.duration}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                      />
                      {sortConfig.key === "duration" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("duration")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("duration")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("duration")}
                        />
                      )}
                    </span>
                  </th>

                  <th className="w-[8%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={lease.rent}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "rent" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("rent")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("rent")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("rent")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[8%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={lease.deposit}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "deposit" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("deposit")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("deposit")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("deposit")}
                        />
                      )}
                    </span>
                  </th>

                  <th className="w-[28%] text pr-4 pb-2">
                    <ParagraphTag
                      content={lease.actions}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {AllLeases.map((item) => {
                  if (`${item?.propertyInfo?.propertyName}`.includes(search)) {
                    return (
                      <tr
                        key={item.id}
                        className="hover:bg-gray-100 rounded-md cursor-pointer"
                      >
                        <td className="py-2">
                          <div className="flex items-center justify-between">
                            <div className="w-[18%] rounded-full">
                              <ImageTag
                                path="/assets/daashboard/balance.png"
                                classes="w-full"
                                altText="logo"
                              />
                            </div>

                            <div
                              className="w-[78%] cursor-pointer"
                              onClick={() => {
                                onViewDetails(item.status, item._id);
                              }}
                            >
                              <ParagraphTag
                                content={item.ID}
                                classes="text-darkColor/50 font-popinsMedium text-xs"
                              />
                              <ParagraphTag
                                content={item?.propertyInfo?.propertyName}
                                classes="text-darkColor font-popinsSemiBold text-sm"
                              />
                            </div>
                          </div>
                        </td>
                        <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                          {item?.unitInfo?.unitName}
                        </td>
                        <td className="py-2 font-popinsMedium text-darkColor text-sm">
                          <span className="flex justify-center">
                            {(() => {
                              switch (item.status) {
                                case "active":
                                  return <SquareCheck color="#01CB78" size={20} />;
                                case "notapprove":
                                  return <CircleHelp color="#B3B3B3" size={20} />;
                                default:
                                  return <SquareX color="#E43B3B" size={20} />;
                              }
                            })()}
                          </span>
                        </td>

                        <td className="py-2 px-4 font-popinsMedium text-darkColor text-xs text-center">
                          <div className="flex flex-col">
                            <span>{`${moment(item?.startDate).format(
                              "DD/MM/YYYY"
                            )} - ${moment(item?.endDate).format(
                              "DD/MM/YYYY"
                            )}`}</span>
                            <span className="text-darkColor/50">{`${CalculateDaysBetween(
                              new Date(),
                              item?.endDate
                            )} Days left`}</span>
                          </div>
                        </td>
                        <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                          {`$ ${item?.additionalCharges?.reduce(
                            (total, item) => total + (Number(item.amount) || 0),
                            0
                          )}`}
                        </td>
                        <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                          {`$ ${item?.securityDepositAmount
                            ? item?.securityDepositAmount
                            : 0
                            }`}
                        </td>
                        <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm">
                          <div className="flex justify-end">
                            <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                              {`${item?.tenantInfo.firstName} ${item?.tenantInfo.lastName}`}
                            </td>
                            <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                              {`${item?.tenantInfo.cellNo} `}
                            </td>
                          </div>
                        </td>
                      </tr>
                    )

                  }

                })}
              </tbody>
            </table>
            {loading && (
              // <div className="flex justify-center items-center w-full mt-20">
              <LeaseTableLoader />
              // </div>
            )}
            {(AllLeases.length === 0 && !loading) && (
              <div className="w-full flex justify-center mt-5">
                <NoData />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center h-[13%] items-center">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          pageCount={Math.ceil(data.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName="flex space-x-2 items-center"
          pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
          previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
          nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
          disabledClassName="opacity-50 !cursor-not-allowed"
          activeClassName="bg-themeColor text-white"
          breakClassName="px-3 py-1 text-sm font-popinsMedium"
        />
      </div> */}
    </div>
  );
};

export default LeasesTable;
