import React, { useRef, useState } from "react";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import moment from "moment";
import { motion } from "framer-motion";
import { ChevronDown, ChevronUp } from "lucide-react";

const TenantSummary = ({ allData }) => {
  const tableRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const downloadPDF = () => {
    toPng(tableRef.current, { quality: 1 })
      .then((dataUrl) => {
        const pdf = new jsPDF("p", "mm", "a4");

        // Heading add karna
        pdf.setFontSize(18);
        pdf.text("All Leases Summary", 105, 20, { align: "center" });

        const imgWidth = 190;
        const imgHeight =
          (tableRef.current.clientHeight * imgWidth) /
          tableRef.current.clientWidth;

        pdf.addImage(dataUrl, "PNG", 10, 30, imgWidth, imgHeight);
        pdf.save("Tenant_Summary.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };


  function calculatePercentage(total, value) {
    if (total === 0) return 0; // To avoid division by zero
    return `${(value / total) * 100}%`;
  }

  function calculateRentPerSF(rent, totalSF) {
    if (totalSF === 0) {
      return 0; // Avoid division by zero
    }
    return rent / totalSF;
  }

  function calculateTotalAmount(items) {
    return items.reduce((total, item) => total + parseFloat(item.amount), 0);
  }
  return (
    <div className="w-full mt-5">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <button
          className="flex w-full justify-between items-center p-4 text-left"
          onClick={() => toggleAccordion(1)}
        >
          {/* <span className="text-lg font-medium">{item.title}</span> */}
          <div className="flex justify-between items-center ">
            <h2 className="text-xl text-darkColor font-popinsSemiBold">All Leases Summary</h2>
          </div>
          <div className="flex items-center">
            <button
              className="px-4 py-2 mr-2 bg-themeColor text-white rounded-md font-popinsMedium"
              onClick={downloadPDF}
            >
              Download PDF
            </button>
            {activeIndex === 1 ? <ChevronUp /> : <ChevronDown />}
          </div>
        </button>

        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: activeIndex === 1 ? "auto" : 0,
            opacity: activeIndex === 1 ? 1 : 0,
          }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="overflow-hidden"
        >
          <div className="w-full">
            <div
              ref={tableRef}
              className="overflow-auto w-full p-4 bg-white "
            >
              <table className="w-full shadow-sm text-sm border-2 rounded-md border-gray-100">
                <thead>
                  <tr className="border-b border-black/20 text-center text-[13px] text-darkColor/40">
                    <th className="w-[11%] py-2 font-popinsRegular">
                      Tenant Name
                    </th>
                    <th className="w-[11%] py-2 font-popinsRegular">Suite</th>
                    <th className="w-[11%] py-2 font-popinsRegular">SF</th>
                    <th className="w-[11%] py-2 font-popinsRegular">
                      BLDG Share
                    </th>
                    <th className="w-[11%] py-2 font-popinsRegular">
                      Lease Comm.
                    </th>
                    <th className="w-[11%] py-2 font-popinsRegular">
                      Lease Exp.
                    </th>
                    <th className="w-[11%] py-2 font-popinsRegular">
                      Rent / SF
                    </th>
                    <th className="w-[11%] py-2 font-popinsRegular">
                      Per Month
                    </th>
                    <th className="w-[11%] py-2 font-popinsRegular">
                      Per Year
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((item, index) => {
                    const TotalRentAmount = calculateTotalAmount(
                      item?.additionalCharges
                    );
                    return (
                      <tr key={index} className="font-popinsRegular even:bg-themeColor/40 text-center">
                        <td className="py-2">{`${item?.tenantInfo?.firstName} ${item?.tenantInfo?.lastName} `}</td>
                        <td className="py-2">
                          {item?.unitInfo?.unitName}
                        </td>
                        <td className="py-2">
                          {item?.unitInfo?.tenantSize}
                        </td>
                        <td className="py-2">
                          {calculatePercentage(
                            item?.unitInfo?.size,
                            item?.unitInfo?.tenantSize
                          )}
                        </td>
                        <td className="py-2">
                          {moment(item?.startDate).format("DD/MM/YY")}
                        </td>
                        <td className="py-2">
                          {moment(item?.endDate).format("DD/MM/YY")}
                        </td>
                        <td className="py-2">
                          $
                          {calculateRentPerSF(
                            TotalRentAmount,
                            item?.unitInfo?.tenantSize
                          )?.toFixed(2)}
                        </td>
                        <td className="py-2">
                          ${TotalRentAmount?.toLocaleString()}
                        </td>
                        <td className="py-2">
                          {item?.unitInfo?.rentType === "Yearly"
                            ? `$${TotalRentAmount * 12}`
                            : item?.unitInfo?.rentType}
                        </td>
                      </tr>
                    );
                  })}


                </tbody>
              </table>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default TenantSummary;
