import React from 'react';
import ImageTag from '../../component/reusebale/imageTag'
import HeadingTag from '../../component/reusebale/heading'
import ParagraphTag from '../../component/reusebale/Paragraph'
import db from '../../db/index.json'
import FormSection from '../../component/signup-section/form'

function Signup() {

  const { signUp } = db;

  return (
    <div className='lg:h-screen flex items-center py-12 lg:py-0'>
      <div className='flex w-[90%] mx-auto lg:h-[90vh] rounded-xl'>
        <div className='w-[50%] h-full hidden lg:block'>
          <ImageTag path='/assets/auth/auth-image.png' classes='w-full h-full' altText='signup'/>
        </div>
        <div className='w-full lg:w-[50%] bg-[#FFFFFF] flex flex-col items-center justify-center h-full'>
          <div className='w-[90%] lg:w-[80%] mx-auto flex flex-col items-center justify-center'>
            <ImageTag path='/assets/logo.png' classes='w-full lg:w-[55%] h-32' altText='logo'/>
            <div className='w-full pt-3 h-full'>
              <HeadingTag title={signUp.title} classes='font-popinsSemiBold text-themeColor text-3xl'/>
              <ParagraphTag content={signUp.description} classes='text-paraColor text-sm pt-2 font-popinsRegular'/>
              <FormSection/>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Signup;
