import React, { useEffect, useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";
import ProgressPopup from "../../../popup/progressPopup";
import db from "../../../../db/index.json";
import {
  CarouselProvider,
  Slider,
  Slide
} from "pure-react-carousel";
import moment from "moment";
import CompanyInfo from "../../leases/leaseDetail/company-info";
import LandlordInfo from "../../leases/leaseDetail/landrlordInfo";
import BaseUrl from "../../../../constants/BaseUrl";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import { GetNotificationMessageByKey } from "../../../../db/NotificationMsgList";
import { useSelector } from "react-redux"
const OverviewRequest = ({
  data,
  tenantInfo,
  onRehit
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { maintainances } = db;

  const [loading, setLoading] = useState()
  const openPopup = () => {
    setShowSuccess(true);
  };
  const { loginSuccess } = useSelector(
    (state) => state.auth
  );





  const closePopup = () => {

    setShowSuccess(false);
  };


  const CreateNotification = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "landlordId": loginSuccess.user._id,
      "title": GetNotificationMessageByKey("update_maintenance").title,
      "description": `${GetNotificationMessageByKey("update_maintenance").description} ${data?.propertyInfo.propertyName}`
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/notification/createNotification`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          // ShowSuccess("Notification Created Successfully")
        }
      })
      .catch((error) => console.error(error));
  }

  const onStatusChange = () => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "recordId": data?._id,
      "status": status
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/maintenence/statusUpdate`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          CreateNotification()
          onRehit()
          setLoading(false)
          closePopup()
          ShowSuccess("Status updated successfully")
        } else {
          setLoading(false)
          ShowError(result.message)
        }
      })
      .catch((error) => console.error(error));
  }


  const [status, setStatus] = useState("")

  useEffect(() => {
    if (data) {
      setStatus(data.status)
    }
  }, [data])

  return (
    <div className="w-full">
      <div className="bg-white rounded-lg w-full pt-5">
        <div className="w-[95%] mx-auto">
          <div className="flex w-full justify-between">
            <ParagraphTag
              content="Request Overview"
              classes={`text-darkColor font-popinsSemiBold text-lg`}
            />
            <div className="bg-themeColor flex justify-ceneter items-center rounded-md text-white px-2 py-1" >
              <ParagraphTag
                content={data?.status}
                classes={` font-popinsSemiBold text-xs`}
              />

            </div>

          </div>
          <div className="flex justify-between mt-4">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Property"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={data?.propertyInfo?.propertyName}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[40%]">
              <div>
                <ParagraphTag
                  content="Unit"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={data?.unitInfo?.unitName}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-2">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Category"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={data?.requestCategory?.category}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>

            <div className=" w-[40%]">
              <div>
                <ParagraphTag
                  content="Create Date"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={moment(data?.requestDate).format(
                      "DD/MM/YYYY"
                    )}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="flex justify-between mt-2">


          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Caller Name"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={data?.callerInformation?.name}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[40%]">
              <div>
                <ParagraphTag
                  content="Caller Phone"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={data?.callerInformation?.number}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="flex justify-between mt-2">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Caller Email"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={data?.callerInformation?.email}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>

          </div>

          <div className="mt-2">
            <ParagraphTag
              content="Description"
              classes={`text-darkColor font-popinsSemiBold text-sm`}
            />
            <div>
              <ParagraphTag
                content={data?.description}
                classes={`text-darkColor/40 font-popinsRegular text-xs`}
              />
            </div>
          </div>
          <div className=" w-[100%] mt-2">
            <div>
              <ParagraphTag
                content="Tech Note"
                classes={`text-darkColor font-popinsSemiBold text-sm`}
              />
              <div>
                <ParagraphTag
                  content={data?.techNote}
                  classes={`text-darkColor/40 font-popinsRegular text-xs`}
                />
              </div>
            </div>
          </div>


          <div className=" w-[100%] mt-2">
            <div>
              <ParagraphTag
                content="Access Note"
                classes={`text-darkColor font-popinsSemiBold text-sm`}
              />
              <div>
                <ParagraphTag
                  content={data?.accessNote}
                  classes={`text-darkColor/40 font-popinsRegular text-xs`}
                />
              </div>
            </div>
          </div>

          <div className="w-[40%] mx-auto mt-5 pb-5" onClick={openPopup}>
            <ButtonTag
              name="Update Progress"
              classes="font-popinsMedium text-sm text-center bg-themeColor text-white"
            />
          </div>
        </div>
      </div>

      <div className="mt-2">
        {
          tenantInfo && <LandlordInfo
            data={tenantInfo}
          />
        }
      </div>
      <div className="mt-2">
        {
          tenantInfo && <CompanyInfo
            data={tenantInfo}
          />
        }
      </div>

      {
        data?.imageList?.length !== 0 && <div className="bg-white rounded-lg mt-3 px-4 py-5">
          <div>
            <ParagraphTag
              content="Images"
              classes={`text-darkColor font-popinsSemiBold text-lg mb-3`}
            />
            <CarouselProvider
              naturalSlideWidth={19} // 3 images per slide, each taking 33.33% of the container width
              naturalSlideHeight={20} // Set height of images to 120px
              totalSlides={maintainances.dummyImage.length}
              visibleSlides={5} // Display 3 images at once
              infinite
              step={1} // Slide one by one
              isDragEnabled={maintainances?.dummyImage?.length > 3} // Enable drag functionality
              dragVelocity={2} // Adjust drag speed (optional)
            >
              <div className="relative w-full">
                <Slider>
                  {data?.imageList.map((image, index) => (
                    <Slide key={index} index={index}>
                      <div className="w-[90%] h-[100px] relative overflow-hidden">
                        <img
                          src={image.imageUrl}
                          alt={`Slide ${index}`}
                          className="w-full h-full object-cover" // Ensures images cover the full height and width
                        />
                      </div>
                    </Slide>
                  ))}
                </Slider>
              </div>
            </CarouselProvider>
          </div>
        </div>
      }

      <div className="mt-10">

      </div>
      {showSuccess && (
        <ProgressPopup
          loader={loading}
          onSelecStatus={(e) => {
            // alert(e.target.value)
            setStatus(e.target.value)
          }} selectedStatusValue={status} onFilter={onStatusChange} onBack={closePopup} />
      )}
    </div>
  );
};

export default OverviewRequest;
