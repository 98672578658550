import React, { useEffect, useState } from "react";
import { Plus } from "lucide-react";
import ParagraphTag from "../../reusebale/Paragraph";
import TutorialPopup from "../../popup/createTutorial"
import BaseUrl from "../../../constants/BaseUrl"
import NoData from "../../reusebale/noData"

const TutorialSection = ({ onViewCreate, search }) => {
  const [isShowPopup, setShowPopup] = useState(false)

  const [data, setData] = useState([

  ]);

  const handleOpen = () => {
    setShowPopup(true)
  }

  const handleClose = () => {
    setShowPopup(false)
  }

  const GetAllTutorials = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/tutorials/getAllTutorialsByRole?page=1&limit=200&role=Landlord`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          setData(result.data)
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    GetAllTutorials()
  }, [])

  return (
    <div className="h-full pt-4">
      <div className="bg-white w-full rounded-lg h-[87%]">
        <div className="w-[95%] mx-auto h-full">
          {/* <div className="flex justify-between items-center h-[12%]">
            <div className="w-full flex justify-end">
              <button
                className="w-[20%] bg-themeColor rounded-md flex items-center justify-center py-2"
                onClick={onViewCreate}
              >
                <Plus color="#FFFFFF" size={15} />
                <ParagraphTag
                  content="Create New"
                  classes="text-white font-popinsSemiBold text-xs pl-2"
                  onTrigger={handleOpen}
                />
              </button>
            </div>
          </div> */}
          <div className="overflow-x-scroll w-full h-full panelScroll pt-2 flex flex-wrap" id="gk-cards">
            {data?.map((item, i) => {
              if (String(item?.title)?.toLocaleUpperCase().includes(String(search).toLocaleUpperCase())) {
                return (<div className="w-[32%] mr-[2%] pt-3" key={i}>
                  <div className="overflow-hidden">
                    <iframe
                      className="w-full h-56 rounded-2xl shadow-lg"
                      src={
                        item?.link?.replace("watch?v=", "embed/").split("&")[0] +
                        "?rel=0&modestbranding=1"
                      }
                      title={item.title}
                      allowFullScreen
                    ></iframe>
                  </div>
                  <ParagraphTag
                    content={item?.title}
                    classes="text-dark font-popinsRegular text-md flex justify-center items-center my-2"
                  />
                </div>)
              }
            })}
            {data.length === 0  && (
              <div className="w-full h-full flex items-center">
                <NoData />
              </div>
             )} 
          </div>
        </div>
      </div>
      {isShowPopup && <TutorialPopup onBack={handleClose} />}
    </div>
  );
};

export default TutorialSection;
