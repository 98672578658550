import React, { useState } from "react";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import CompanyForm from "../company-information/company-form";
import UploadImage from "../company-information/upload-image";
import { useSelector } from "react-redux"
import BaseUrl from "../../../../constants/BaseUrl";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";

const CompanyInfo = ({ onBack }) => {
  const { loader, loginSuccess, userCompleteProfile } = useSelector((state) => state.auth)
  const [imageUrl, setImageUrl] = useState(null)
  const [loading, setLoading] = useState(false)



  const UploadImageToServerImage = (file) => {
    setLoading(true)
    const formdata = new FormData();
    formdata.append("image", file);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/uploads/upload`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.url) {
          setLoading(false)
          setImageUrl(result.url)
        } else {
          ShowError("Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB")
        }
      })
      .catch((error) => {
        setLoading(false)
        ShowError("Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB")
      });
  }


  return (
    <div className="mt-6 w-full">
      <Breadcrumbs recordId={`${loginSuccess?.user?.firstName} ${loginSuccess?.user?.lastName}`} subheading='My Account' onBack={onBack} />

      <div className="flex justify-between mt-5">
        <div className="w-[64%]">
          <CompanyForm imageUrl={imageUrl} onBack={onBack} />
        </div>
        <div className="w-[34%]">

          <UploadImage
            loading={loading}
            imageUploadFunction={(imagePath) => {
              UploadImageToServerImage(imagePath)
            }}
            imagePath={imageUrl ? imageUrl : userCompleteProfile?.companyInformation?.companyLogo}
            imageTitle="Profile Image"
          />
          {/* <UploadImage
            imagePath={userCompleteProfile?.companyInformation?.companyLogo}

          /> */}
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
