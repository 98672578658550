export const NotificationMsgList = [
    {
        key: "new_lease",
        title: "Lease Creation",
        description: "A new lease has been created for the property"
    },
    {
        key: "new_update",
        title: "Lease Update",
        description: "The lease status has been updated for the property"
    },
    {
        key: "maintenance_creation",
        title: "New Maintenance Request",
        description: "A new maintenance request has been created for the property"
    },
    {
        key: "update_maintenance",
        title: "Maintenance Request Update",
        description: "The maintenance request status has been updated for the property"
    },
];
export function GetNotificationMessageByKey(key) {
    return NotificationMsgList.find(item => item.key === key) || null;
}