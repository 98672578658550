import React, { useEffect, useState } from "react";
import OverviewSection from "./overview";
import UnitsSection from "./units";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import MaintenanceRequestSection from "../../home/maintainance-request";
import ReceivePayment from "../../home/receivePayment";
import LeaseTableSection from "../property-detail/leaseSection";
import ImageCarousel from "../property-detail/image-carousel";
import db from "../../../../db/index.json";
import BaseUrl from "../../../../constants/BaseUrl";
import MyLoader from "../../../reusebale/myLoader";
import { Plus } from "lucide-react";
import ParagraphTag from "../../../../component/reusebale/Paragraph";
import LeaseSummaryReportPopup from "../../../reports/LeaseSummryReportPopup";
import PropertyLoader from "../../../skeleton-loader/propety-detail";

const MainSection = ({
  onBack,
  propertyId,
  onViewDetailsMaintain,
  onMaintainancePage,
  onCreateLease,
  toggleLeaseDetail,
}) => {
  const [allDetails, setAllDetails] = useState();
  const [loader, setLoader] = useState();
  const { properties } = db;

  const GetPropertyDetails = () => {
    setLoader(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/property/getPropertyDetails/${propertyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setLoader(false);
          setAllDetails(result.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const [showReport, setShowReport] = useState(false);
  const [reportData, setReportData] = useState([false]);

  const GetAllReportData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/lease/getAllLeaseByProperty/${propertyId}?page=1&limit=500`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setReportData(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetPropertyDetails();
    GetAllReportData();
  }, [propertyId]);

  // if (loader) {
  //   return (
  //     <div className="flex h-screen justify-center items-center">
  //       <MyLoader />
  //     </div>
  //   );
  // } else {
    return (
      <div className="mb-8">
        <div className="mt-6 w-full flex">
          <div className="w-[80%]">
            <Breadcrumbs
              classes={`text-darkColor font-popinsSemiBold text-lg`}
              recordId={`PROP-${allDetails?._id?.slice(1, 10)}`}
              subheading="Property Details"
              onBack={onBack}
            />
          </div>

          <div className="w-[20%] flex justify-end">
            <button
              className="w-full bg-themeColor font-popinsMedium rounded-md flex items-center justify-center py-2"
              onClick={() => {
                setShowReport(true);
              }}
            >
              <ParagraphTag
                content="Show Report"
                classes="text-white font-popinsSemiBold text-xs pl-2"
                onTrigger={() => {
                  setShowReport(true);
                }}
              />
            </button>
          </div>
        </div>
        {loader && <PropertyLoader />}
        {!loader && (
          <>
            <OverviewSection allDetails={allDetails} />
            <ImageCarousel allDetails={allDetails} />

            <UnitsSection allDetails={allDetails} />
            <div className="flex justify-between mt-4">
              <div className="w-[49%]">
                <MaintenanceRequestSection
                  onViewDetails={onViewDetailsMaintain}
                  onMaintainanceTable={onMaintainancePage}
                />
              </div>
              <div className="w-[49%]">
                <ReceivePayment />
              </div>
            </div>
            <LeaseTableSection
              createLease={onCreateLease}
              isViewDetails={toggleLeaseDetail}
            />
            <LeaseSummaryReportPopup
              PropertyName={allDetails?.propertyName}
              onClose={() => setShowReport(false)}
              isOpen={showReport}
              allData={reportData}
            />
          </>
        )}
      </div>
    );
  }
// };

export default MainSection;
