import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import BaseUrl from "../../../constants/BaseUrl";
import { useSelector } from "react-redux"

export default function StatusChart() {
  const { rightPanel } = db;
  const { loader, loginSuccess, loginError } = useSelector(
    (state) => state.auth
  );


  const [totalLease, setTotalLease] = useState(0)
  const [initialData, setInitialData] = useState([
    {
      "label": "Not Started",
      "count": 0,
      "percentage": "0",
      "color": "#5267ED"
    },
    {
      "label": "Lease Accepted",
      "count": 0,
      "percentage": "0",
      "color": "#01CB78"
    },
    {
      "label": "Lease Rejected",
      "count": 0,
      "percentage": "0",
      "color": "#F48051"
    }
  ])

  const GetStatsData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/lease/getLeaseStatusStatsByLandlordId/${loginSuccess.user._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          if (result?.data?.length != 0) {
            setInitialData(result?.data)
          }

          setTotalLease(result?.totalLeases)
        }
      })
      .catch((error) => console.error(error));
  }
  useEffect(() => {
    GetStatsData()
  }, [])

  return (
    <div className="bg-white rounded-2xl py-5 px-4 w-full mt-5 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="">
        <ParagraphTag
          content={rightPanel.stats}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
      </div>
      <div className="flex flex-col items-end">
        <div>

          <ParagraphTag
            content={`${rightPanel.total} ${totalLease}`}
            classes="text-darkColor font-popinsBold text-lg"
          />
        </div>
      </div>
      <div className="flex items-center space-x-4 mt-4">
        <div className="flex flex-col justify-end h-36 w-8">
          {initialData.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: item.color,
                height: `${item.percentage}%`,
              }}
            />
          ))}
        </div>
        <div className="flex-1">
          {initialData.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-3">
              <div className="flex items-center">
                <div
                  style={{ backgroundColor: item.color }}
                  className="w-4 h-4 mr-2 rounded-sm"
                />
                <ParagraphTag
                  content={item.label}
                  classes="mr-4 text-darkColor/40 font-popinsMedium text-sm"
                />
              </div>
              <div className="flex items-center">
                <ParagraphTag
                  content={item.count}
                  classes="mr-4 text-darkColor/40 font-popinsMedium text-sm"
                />
                <ParagraphTag
                  content={`${item.percentage}%`}
                  classes="mr-4 text-darkColor/40 font-popinsMedium text-sm"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
