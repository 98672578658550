import React from "react";
import ParagraphTag from "../reusebale/Paragraph";
import LabelTag from "../reusebale/label";
import ButtonTag from '../reusebale/button';
import MyLoader from "../reusebale/myLoader"
const ProgressPopup = ({ onFilter, onBack, selectedStatusValue, onSelecStatus, loader }) => {

  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[21%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col">
          <div className="text-center">
            <ParagraphTag
              content="Update Progress"
              classes={`text-darkColor font-popinsSemiBold text-sm `}
            />

          </div>
          <div className="w-full px-2 mt-3">
            <LabelTag isStaric={false} name="Select Status" classes='!text-xs' />
            <select onChange={onSelecStatus} value={selectedStatusValue} className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor/40 placeholder:text-zinc-700/50 font-popinsRegular">
              <option value="Parts Ordered">Parts Ordered</option>
              <option value="In Progress">In Progress</option>
              <option value="Completed">Completed</option>
            </select>

          </div>

          <div className="w-full px-2">
            <div>
              {loader ? <MyLoader /> : <ButtonTag onSubmit={onFilter} name='Submit' classes='text-sm bg-themeColor hover:bg-themeColor/90 mt-3 text-center  text-white' />}
            </div>

            <p className="text-darkColor pt-2 flex item-center justify-center cursor-pointer font-popinsMedium text-sm" onClick={onBack}>Close</p>
          </div>

        </div>
      </section>
    </main>
  );
};

export default ProgressPopup;
