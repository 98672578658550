import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph"
import HeadingTag from "../../reusebale/heading"
import { Search } from "lucide-react";
import ReportCard from "./report-card";
import db from "../../../db/index.json";
import TenantSummary from "../../reports/TenantSummryReports";
import { useSelector } from "react-redux"
import BaseUrl from "../../../constants/BaseUrl"
import MaintenanceSummryReports from "../../reports/MaintenanceSummryReports";
const ReportsSection = () => {
  const { reports } = db;
  const { loader, loginSuccess, loginError } = useSelector(
    (state) => state.auth
  );


  const [allData, setAllData] = useState([])
  const GetAllLeaseData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/lease/getAllLeaseByLanlordId/${loginSuccess.user._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllData(result.data)
        }
      })
      .catch((error) => console.error(error));
  }

  const [maintenanceData, setMaintenanceData] = useState([])
  const GetMaintenanceData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/maintenence/getAllMaintenanceRequests?page=1&limit=5000`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setMaintenanceData(result.data)
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    GetAllLeaseData()
    GetMaintenanceData()
  }, [])


  return (
    <div className="w-full h-screen">
      <div className="flex justify-between items-center pt-4 h-[10vh]">
        <div className="w-[70%]">
          <HeadingTag
            title='Reports'
            classes="font-popinsSemiBold text-black text-xl mt-[-4px]"
          />
          <ParagraphTag
            content='All available reports are listed in this section.'
            classes="text-[#686868] font-popinsRegular text-xs"
          />
        </div>
        <div className="w-[30%]">
          <div className="flex justify-between items-center">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Search"
                className="w-full font-popinsRegular mt-1 bg-[#E6BF9F4D] pl-10 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
              <div className="absolute top-[14px] left-4">
                <Search color="#1A1A1A5D" size={15} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between mt-3 h-[90vh]">
        <div className="w-[100%] panelScroll h-full overflow-x-auto pb-5">
          <TenantSummary allData={allData} />
          <MaintenanceSummryReports allData={maintenanceData}/>
          {/* <ReportCard item={reports.favourites.rating} title={reports.favourites.title} />
          <ReportCard item={reports.leasing.rating} title={reports.leasing.title} />
          <ReportCard item={reports.whatYouOwe1.rating} title={reports.whatYouOwe1.title} /> */}
        </div>

      </div>
    </div>
  );
};

export default ReportsSection;
