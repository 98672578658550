import React from "react";
import ParagraphTag from "../reusebale/Paragraph"
import HeadingTag from "../reusebale/heading"
import ImageTag from "../reusebale/imageTag"
import { Search } from "lucide-react";

const SearchBar = (props) => {

  const { title, desc, onSubmit, filterShow } = props;

  return (
    <div className="flex justify-between items-center pt-4">
      <div className="w-[70%]">
        <HeadingTag
          title={title}
          classes="font-popinsSemiBold text-black text-lg"
        />
        <ParagraphTag
          content={desc}
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <div className="w-[30%]">
        <div className="flex justify-between items-center">
          <div className="relative w-full">
            <input
              value={props.value}
              onChange={props.onSearchChange}
              type="text"
              placeholder="Search"
              className="w-full font-popinsRegular mt-1 bg-[#E6BF9F4D] pl-10 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
            <div className="absolute top-[14px] left-4">
              <Search color="#1A1A1A5D" size={15} />
            </div>
          </div>
          {
            filterShow && <div className="cursor-pointer w-10 h-8 rounded-md flex justify-center items-center ml-3 bg-[#0381CA]" onClick={onSubmit}>
              <ImageTag path='/assets/daashboard/bar.png' classes=' h-[14px]' altText='logo' />
            </div>
          }

        </div>
      </div>
    </div>
  );
}

export default SearchBar;
