import React, { useEffect, useState } from "react";
import InfoSection from "../tenantsDetail/info";
import LeaseSection from "../tenantsDetail/leaseSection";
import MaintainanceSection from "../tenantsDetail/maintainance";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import InfoCards from "../../../reusebale/cards";
import db from "../../../../db/index.json";
import BaseUrl from "../../../../constants/BaseUrl";
import CompanyInfoSection from "./companyInfo";
import { useSelector } from "react-redux";
import TenantLoader from "../../../skeleton-loader/tennant-detail";

const MainTenantSection = ({
  onBack,
  seletedTenant,
  onViewMaintainDetails,
  onViewMaintainCreate,
  toggleLeaseDetail,
  toggleCreateLease,
  onTriggerCard,
}) => {
  const { tenants } = db;
  const [tenantProfileInfo, setTenantProfileInfor] = useState();
  const [maintenanceRequest, setMaintenancRequest] = useState([]);
  const [AllLease, setAllLeases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { loginSuccess } = useSelector((state) => state.auth);

  const GetTenantProfileInformation = async () => {
    try {
      const response = await fetch(
        `${BaseUrl}tenant/profile/getProfileDetails/${seletedTenant?._id}`
      );
      const result = await response.json();
      if (result.status === "Success") {
        setTenantProfileInfor(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetMaintenanceRequetByTenantId = async () => {
    try {
      const response = await fetch(
        `${BaseUrl}api/maintenence/getMeintenanceRequestByTenantId/${seletedTenant?._id}`
      );
      const result = await response.json();
      if (result.success) {
        setMaintenancRequest(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetAllleasesByLandloardAndTenantId = async () => {
    try {
      const response = await fetch(
        `${BaseUrl}api/lease/getAllLeaseByLandlordAndTenant/${loginSuccess.user._id}/${seletedTenant?._id}`
      );
      const result = await response.json();
      if (result.success) {
        setAllLeases(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        GetTenantProfileInformation(),
        GetMaintenanceRequetByTenantId(),
        GetAllleasesByLandloardAndTenantId(),
      ]);
      setIsLoading(false);
    };
    fetchData();
  }, []);


  return (
    <div className="mt-6">
      <Breadcrumbs
        classes={`text-darkColor font-popinsSemiBold text-xl`}
        recordId={`${seletedTenant?.firstName} ${seletedTenant?.lastName}`}
        subheading="Tenant Information"
        onBack={onBack}
      />
      {
        isLoading && 
        <TenantLoader/>
      }
      {!isLoading && (
        <>
          <div className="flex justify-between">
            <div className="w-[49%]">
              <InfoSection tenantInfo={tenantProfileInfo} />
            </div>
            <div className="w-[49%]">
              <CompanyInfoSection CompanyInfo={tenantProfileInfo} />
            </div>
          </div>

          <LeaseSection
            AllLeases={AllLease}
            isViewDetails={toggleLeaseDetail}
            toggleCreateLease={toggleCreateLease}
          />
          <MaintainanceSection
            maintenanceRequest={maintenanceRequest}
            onViewDetails={onViewMaintainDetails}
            onViewCreate={onViewMaintainCreate}
          />
          <div className="flex flex-wrap my-5" id="gk-cards">
            {tenants.cardData2.map((item, i) => (
              <InfoCards
                key={i}
                item={item}
                classes="mr-[2%]"
                onTrigger={onTriggerCard}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MainTenantSection;
