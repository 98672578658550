import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import {
  Plus,
  SquareCheck,
  ArrowUpDown,
  ArrowDownUp,
  CircleHelp,
  SquareX,
} from "lucide-react";
import db from "../../../../db/index.json";
import NoData from "../../../reusebale/noData";
import moment from "moment"

const LeaseSection = ({ AllLeases, isViewDetails, toggleCreateLease }) => {

  const { lease } = db;
  const [data, setData] = useState([
    {
      id: 1,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 2,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 3,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "notapprove",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
  ]);
  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  const onViewDetails = (status, id) => {
    isViewDetails(status, id);
  };

  function CalculateDaysBetween(date1, date2) {
    // Parse the dates using Moment.js
    const startDate = moment(date1);
    const endDate = moment(date2);

    // Calculate the difference in days
    const daysDifference = endDate.diff(startDate, "days");

    return daysDifference;
  }

  return (
    <div className="bg-white rounded-lg mt-5">
      <div className="w-[95%] mx-auto py-6">
        <div className="flex">
          <div className="w-[65%]">
            <ParagraphTag
              content="Leases"
              classes="text-darkColor font-popinsSemiBold text-lg"
            />
          </div>
          {/* <div className="w-[35%] flex justify-end">
            <button className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2" onClick={toggleCreateLease}>
              <Plus color="#FFFFFF" size={15} />
              <ParagraphTag
                content="New Lease"
                classes="text-white font-popinsSemiBold text-xs pl-2"
              />
            </button>
          </div> */}
        </div>
        <div className="w-full mt-5 overflow-x-auto h-[300px] panelScroll">
          <table className="w-full ">
            <thead className="sticky top-0 bg-white">
              <tr className="border-b border-black/20">
                <th className="w-[20%] text-left pb-2">
                  <span className="flex">
                    <ParagraphTag
                      content={lease.heading}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "lease" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("property")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[8%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.units}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "units" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("units")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("units")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("units")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[8%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.status}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "status" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("status")}
                      />
                    )}
                  </span>
                </th>

                <th className="w-[20%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.duration}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                    />
                    {sortConfig.key === "duration" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("duration")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("duration")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("duration")}
                      />
                    )}
                  </span>
                </th>

                <th className="w-[8%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.rent}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "rent" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("rent")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("rent")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("rent")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[8%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.deposit}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "deposit" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("deposit")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("deposit")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("deposit")}
                      />
                    )}
                  </span>
                </th>

                <th className="w-[28%] text pr-4 pb-2">
                  <ParagraphTag
                    content={lease.actions}
                    classes="text-darkColor/40 font-popinsSemiBold text-xs"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {AllLeases.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-gray-100 rounded-md cursor-pointer"
                >
                  <td className="py-2">
                    <div className="flex items-center justify-between">
                      <div className="w-[18%] rounded-full">
                        <ImageTag
                          path="/assets/daashboard/balance.png"
                          classes="w-full"
                          altText="logo"
                        />
                      </div>

                      <div
                        className="w-[78%] cursor-pointer"
                        onClick={() => {
                          onViewDetails(item.status, item._id);
                        }}
                      >
                        <ParagraphTag
                          content={item.ID}
                          classes="text-darkColor/50 font-popinsMedium text-xs"
                        />
                        <ParagraphTag
                          content={item?.propertyInfo?.propertyName}
                          classes="text-darkColor font-popinsSemiBold text-sm"
                        />
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {item?.unitInfo?.unitName}
                  </td>
                  <td className="py-2 font-popinsMedium text-darkColor text-sm">
                    <span className="flex justify-center">
                      {(() => {
                        switch (item.status) {
                          case "active":
                            return <SquareCheck color="#01CB78" size={20} />;
                          case "notapprove":
                            return <CircleHelp color="#B3B3B3" size={20} />;
                          default:
                            return <SquareX color="#E43B3B" size={20} />;
                        }
                      })()}
                    </span>
                  </td>

                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-xs text-center">
                    <div className="flex flex-col">
                      <span>{`${moment(item?.startDate).format(
                        "DD/MM/YYYY"
                      )} - ${moment(item?.endDate).format(
                        "DD/MM/YYYY"
                      )}`}</span>
                      <span className="text-darkColor/50">{`${CalculateDaysBetween(
                        new Date(),
                        item?.endDate
                      )} Days left`}</span>
                    </div>
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {`$ ${item?.additionalCharges?.reduce(
                      (total, item) => total + (Number(item.amount) || 0),
                      0
                    )}`}
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {`$ ${item?.securityDepositAmount
                        ? item?.securityDepositAmount
                        : 0
                      }`}
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm">
                    <div className="flex justify-end">
                      <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                        {`${item?.tenantInfo.firstName} ${item?.tenantInfo.lastName}`}
                      </td>
                      <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                        {`${item?.tenantInfo.cellNo} `}
                      </td>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {AllLeases.length === 0 && (
            <div className="w-full flex justify-center mt-5">
              <NoData />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaseSection;
