import React, { useEffect, useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph"
import Info from "./info"
import Overview from "./overview"
import Credentials from "./credentials"
import { useSelector, useDispatch } from "react-redux"
import * as DataAction from "../../../../store/actions/data/DataAction"
import BaseUrl from "../../../../constants/BaseUrl";
import { List } from "lucide-react";

const CreateBroadCast = ({ onBack, onBackHome }) => {
  const { boradcastStepCount } = useSelector((state) => state.data)
  const { loginSuccess } = useSelector(
    (state) => state.auth
  );
  const steps = ["Info", "Tenants", "Overview"];
  const dispatch = useDispatch()
  // const [currentStep, setCurrentStep] = useState(1);

  const handleStepClick = (step) => {
    dispatch(DataAction.ChangeBroadcastCurrentStep(step))
    // setCurrentStep(step);
  };


  const GetAllTenants = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}tenant/getAllTenantByLanlordId/${loginSuccess.user._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          let data = [...result.data]
          for (const key in data) {
            data[key]["emailSelect"] = true
            data[key]["numberSelect"] = false
          }
          setFormFields({
            ...formFields,
            tenantList: data
          })
        }
      })
      .catch((error) => console.error(error));
  }
  useEffect(() => {
    GetAllTenants()
  }, [])

  const [formFields, setFormFields] = useState({
    title: "",
    description: "",
    tenantList: []
  })


  return (
    <div className="h-screen w-full">
      <div className="h-[10vh] flex flex-col justify-center pt-1">
        <ParagraphTag
          content="Broadcast Request"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Create a broadcast to notify all tenants.."
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <div className="h-[90vh] py-3 w-full">
        <div className="bg-white rounded-md h-full">
          <div className="w-full mx-auto h-[15%]">
            {/* Stepper */}
            <div className="relative w-[70%] mx-auto flex items-center justify-between pt-5">
              {/* Step Circles */}
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center w-full relative"
                >
                  {/* Circle */}
                  <div
                    className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${boradcastStepCount === index + 1
                      ? "border-[#0381CA] bg-white text-[#0381CA]"
                      : boradcastStepCount > index + 1
                        ? "border-[#0381CA] bg-[#0381CA] text-white"
                        : "border-gray-300 bg-white text-gray-500"
                      } font-popinsMedium cursor-pointer transition-all`}
                    onClick={() => handleStepClick(index + 1)}
                  >
                    {index + 1}
                  </div>

                  {/* Step Label */}
                  <p
                    className={`mt-1 text-xs font-popinsRegular text-[#686868] ${boradcastStepCount >= index + 1
                      ? "text-[#0381CA]"
                      : "text-gray-500"
                      }`}
                  >
                    {step}
                  </p>

                  {/* Line Connector */}
                  {index < steps.length - 1 && (
                    <div
                      className={`absolute top-4 left-[calc(50%+16px)] right-[calc(-50%+16px)] h-0.5 ${boradcastStepCount > index + 1
                        ? "bg-[#0381CA]"
                        : "bg-gray-300"
                        }`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>


          <div className="h-[85%] w-full">
            {boradcastStepCount === 1 && <Info

              onTitleChange={(value) => {
                setFormFields({
                  ...formFields,
                  title: value
                })
              }}

              onDescriptionChange={(value) => {
                setFormFields({
                  ...formFields,
                  description: value
                })
              }}

              formFields={formFields}

            />}
            {boradcastStepCount === 2 && <Credentials
              onTenantListUpdate={(List) => {
                setFormFields({
                  ...formFields,
                  tenantList: List
                })
              }}
              formFields={formFields}

            />}

            {boradcastStepCount === 3 && <Overview onBackHome={onBackHome} onCrateSuccess={() => {
              setFormFields({
                title: "",
                description: ""
              })
              GetAllTenants()
              
            }} formFields={formFields} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBroadCast;
