import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import UnitsCard from "../../../reusebale/uniCard";
import UnitDetailPopup from "../../../popup/isUnitDetail";

const PropertyInfo = ({ leaseDetails }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState()
  return (
    <div className="bg-white rounded-xl">
      <div className="px-4 pt-4">
        <ParagraphTag
          content="Property Information"
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
      </div>
      <div className="flex mt-1">
        <div className="w-[30%] flex justify-center pt-4">
          <ImageTag
            path={leaseDetails?.propertyInfo?.thumbnailImage?.url}
            classes="w-[170px] h-[170px] rounded-full object-cover"
            altText="logo"
          />
        </div>
        <div className="w-[70%] px-2">
          <div className="pt-3">
            <ParagraphTag
              content="Property Name"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={leaseDetails?.propertyInfo?.propertyName}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-3">
            <ParagraphTag
              content="Complete Address"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content={"leaseDetails?.propertyInfo?.propertyName"}
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="Category"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={leaseDetails?.propertyInfo?.category}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Type"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={leaseDetails?.propertyInfo?.type}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
          </div>
          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="City"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={leaseDetails?.propertyInfo?.city}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Country"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content={leaseDetails?.propertyInfo?.country}
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 w-[95%] mx-auto">
        <UnitsCard
          isDeleteShow={true}
          onTrigger={() => {
            setIsEdit(true);
            setSelectedUnit(leaseDetails?.unitInfo)
          }}
          data={leaseDetails?.unitInfo}
          classes="w-full my-2 py-3 px-2 cursor-pointer"
        />
      </div>
      {isEdit && (
        <UnitDetailPopup
          selectedUnit={selectedUnit}
          onBack={() => {
            setIsEdit(false);
          }}
        />

        // <EditUnits
        //   selectedUnit={leaseDetails.unitInfo}
        //   onBack={() => {
        //     setIsEdit(false);
        //   }}
        //   unitNameValue={leaseDetails?.unitInfo?.unitName}
        //   buildingValue={leaseDetails.unitInfo?.totalBuilding}
        //   rentValue={leaseDetails.unitInfo?.rent}
        //   rentTypeValue={leaseDetails.unitInfo.rentType}
        //   officesValue={leaseDetails.unitInfo.offices}
        //   bathroomsValue={leaseDetails.unitInfo.bathrooms}
        //   sizeValue={leaseDetails.unitInfo.size}
        //   buildingDepthValue={leaseDetails.unitInfo.buildingDepth}
        //   clearHeightValue={leaseDetails.unitInfo.clearHeight}
        //   parkingRationValue={leaseDetails.unitInfo.parkingRation}
        //   sprinklersValue={leaseDetails.unitInfo.sprinklers}
        //   waterValue={leaseDetails.unitInfo.water}
        //   electricValue={leaseDetails.unitInfo.electric}
        //   waltRollAreaValue={leaseDetails.unitInfo.waltRollArea}
        //   noOfSuitsValue={leaseDetails.unitInfo.numberOfSuites}
        //   loadingDocksValue={leaseDetails.unitInfo.lodingDocks}
        //   gradeLevelDoorsValue={leaseDetails.unitInfo.gradeLevelDoors}
        //   yearBuildValue={leaseDetails.unitInfo.yearBuild}
        //   tenantsSizeValue={leaseDetails.unitInfo.tenantSize}
        //   hvacValue={leaseDetails.unitInfo.hvac}
        //   hvacNumberValue={leaseDetails.unitInfo.hvacValue}
        // />
      )}
    </div>
  );
};

export default PropertyInfo;
