import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import PropertyType from "./type";
import Address from "./address";
import PropertyUnits from "./units";
import BankAccount from "./accounts";
// import ReverseFunds from "./funds";
import PropertyOverview from "./overview";
import { useDispatch, useSelector } from "react-redux";
import * as PropertyAction from "../../../../store/actions/property/PropertyAction";
import { ShowError } from "../../../../utils/ToasFunction";
const CreateProperty = ({ onBack }) => {
  const steps = ["Type", "Address", "Units", "Overview"];
  const currentStep = useSelector((state) => state.property.propertyCreateStep);
  const {
    createNewPropertyBody,
    isUpdate
  } = useSelector((state) => state.property);
  const dispatch = useDispatch();

  // const [currentStep, setCurrentStep] = useState(1);

  const handleStepClick = (step) => {
    // setCurrentStep(step);
  };

  return (
    <div className="h-screen w-full">
      <div className="h-[10vh] flex flex-col justify-center pt-1">
        <ParagraphTag
          content={`${isUpdate ? "Property Updation" : "Property Creation"}`}
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content={`You can ${isUpdate ? "update" : "create new"}   property by completing all the steps.`}
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <div className="h-[90vh] py-3 w-full">
        <div className="bg-white rounded-md h-full">
          <div className="w-full mx-auto h-[15%]">
            {/* Stepper */}
            <div className="relative w-[70%] mx-auto flex items-center justify-between pt-5">
              {/* Step Circles */}
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center w-full relative"
                >
                  {/* Circle */}
                  <div
                    className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${currentStep === index + 1
                      ? "border-[#0381CA] bg-white text-[#0381CA]"
                      : currentStep > index + 1
                        ? "border-[#0381CA] bg-[#0381CA] text-white"
                        : "border-gray-300 bg-white text-gray-500"
                      } font-popinsMedium cursor-pointer transition-all`}
                    onClick={() => {
                      if (index + 1 == 4) {
                        if (!createNewPropertyBody.category) {
                          ShowError("Property type section is incomplete")
                        } else if (!createNewPropertyBody.propertyName) {
                          ShowError("Property address section is incomplete")
                        } else if (createNewPropertyBody.unitData.length === 0) {
                          ShowError("Property unit section is incomplete")
                        }
                      } else {
                        dispatch(
                          PropertyAction.ChangePropertyCreateSteps(index + 1)
                        )
                      }


                    }

                    }
                  >
                    {index + 1}
                  </div>

                  {/* Step Label */}
                  <p
                    className={`mt-1 text-xs font-popinsRegular text-[#686868] ${currentStep >= index + 1
                      ? "text-[#0381CA]"
                      : "text-gray-500"
                      }`}
                  >
                    {step}
                  </p>

                  {/* Line Connector */}
                  {index < steps.length - 1 && (
                    <div
                      className={`absolute top-4 left-[calc(50%+16px)] right-[calc(-50%+16px)] h-0.5 ${currentStep > index + 1 ? "bg-[#0381CA]" : "bg-gray-300"
                        }`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="h-[85%] w-full">
            {currentStep === 1 && <PropertyType />}
            {currentStep === 2 && <Address />}
            {currentStep === 3 && <PropertyUnits />}
            {/* {currentStep === 4 && <BankAccount />} */}
            {/* {currentStep === 5 && <ReverseFunds />} */}
            {currentStep === 4 && <PropertyOverview onClose={onBack} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProperty;
