import React from "react";
import HeadingTag from '../../component/reusebale/heading'
import ParagraphTag from '../../component/reusebale/Paragraph'
import FormSection from '../../component/login-section/form'
import db from '../../db/index.json'
import ImageTag from '../../component/reusebale/imageTag'
import { useSelector } from "react-redux"
function Login() {
  const { login } = db;

  //Calling Data from redux and save into a veriable
  //state= state we setting up in app.js
  //data= is the key name we pass in the combine reducer 
  //allData = is the local store we have created in src/store/reducers/data/dataReducer
  const ReduxData = useSelector((state) => state.data.allData)



  return (
    <div className="lg:h-screen flex items-center py-12 lg:py-0">
      <div className="flex w-[90%] mx-auto lg:h-[90vh] rounded-xl">
        <div className="w-[50%] h-full hidden lg:block">
          <ImageTag path='/assets/auth/auth-image.png' classes='w-full h-full' altText='login' />
        </div>
        <div className="lg:w-[50%] w-full h-full my-auto flex flex-col items-center justify-center bg-white">
          <div className="w-[80%] mx-auto flex flex-col items-center justify-center">
            <ImageTag path='/assets/logo.png' classes='w-full lg:w-[55%] h-32' altText='logo' />
            <div className="w-full pt-1 lg:pt-8 h-full">
              <HeadingTag title={login.title} classes='font-popinsSemiBold text-themeColor xl:text-2xl 2xl:text-3xl' />
              <ParagraphTag content={login.description} classes='text-paraColor text-xs pt-1 font-popinsRegular' />
              <FormSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
