import React, { useEffect, useState } from "react";
import ImageTag from '../../component/reusebale/imageTag'
import HeadingTag from '../../component/reusebale/heading'
import ParagraphTag from '../../component/reusebale/Paragraph'
import db from '../../db/index.json'
import FormSection from '../../component/verification-section/form'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { ShowError, ShowSuccess } from "../../utils/ToasFunction";
import BaseUrl from "../../constants/BaseUrl";
import * as AuthAction from "../../store/actions/auth/AuthAction"
function Verification() {
  const signUpData = JSON.parse(localStorage.getItem('signUpSucc'))
  const { signUpSuccess, loginError } = useSelector((state) => state.auth)

  const { verification } = db;
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoading] = useState(false)
  const [formFields, setFormFields] = useState({
    email: "",
    otpCode: ""
  })

  function IsNumericOnly(input) {
    const regex = /^[0-9]{5}$/;
    return regex.test(input);
  }

  useEffect(() => {
    if (signUpSuccess) {
      setFormFields({
        ...formFields,
        email: signUpSuccess.data.email
      })
    } else if (loginError) {
      setFormFields({
        ...formFields,
        email: loginError.data.email
      })
    } else {
      navigate("/login")
    }
  }, [signUpSuccess, loginError])

  // useEffect(() => {
  //   if (localStorage.getItem('signUpSucc')) {
  // setFormFields({
  //   ...formFields,
  //   email: signUpData.data.email
  // })
  //   } else {
  // navigate("/login")
  //   }
  // }, [signUpData])

  const verifyOtpHandler = (e) => {



    e.preventDefault();
    if (formFields.otpCode === "") {
      ShowError("Enter OTP code first")
    } else if (!IsNumericOnly(formFields.otpCode)) {
      ShowError("Invalid OTP entered")
    } else {
      setLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "email": formFields.email,
        "emailOtp": Number(formFields.otpCode)
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${BaseUrl}landlord/auth/emailOtpVerify`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            localStorage.removeItem("signUpSucc")
            navigate("/login")
            ShowSuccess("Email Verified")
            setLoading(false)
            dispatch(AuthAction.ClearSignUpFieldsAction())
          } else {
            ShowError(result.message)
            setLoading(false)
          }
          // alert(JSON.stringify(result))
        })
        .catch((error) => console.error(error));
    }

  }



  return (
    <div className="lg:h-screen flex items-center py-12 lg:py-0">
      <div className="flex w-[90%] mx-auto lg:h-[90vh] rounded-xl">
        <div className="w-[50%] h-full hidden lg:block">
          <ImageTag path='/assets/auth/auth-image.png' classes='w-full h-full' altText='verify' />
        </div>
        <div className="lg:w-[50%] w-full bg-white">
          <div className="w-[80%] mx-auto flex flex-col items-center justify-center h-full">
            <ImageTag path='/assets/logo.png' classes='w-full lg:w-[55%] h-32' altText='logo' />
            <div className="w-full pt-8">
              <HeadingTag title={verification.title} classes='font-popinsSemiBold text-themeColor text-3xl' />
              <ParagraphTag content={verification.description} classes='text-paraColor text-sm pt-2 font-popinsRegular' />
              <FormSection
                loader={loader}
                onVerifyOtp={verifyOtpHandler}
                onVerify={(e) => {
                  e.preventDefault();
                  ShowSuccess("Otp has to your email address")

                }}
                onChangeOtp={(e) => {
                  setFormFields({
                    ...formFields,
                    otpCode: e.target.value
                  })
                }}
                optValue={formFields.password}
                emailValue={formFields?.email} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verification;
