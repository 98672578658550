import { useState } from "react";

const PropertyLoader = () => {
  const [data, setData] = useState([1, 2, 3, 4]);
  return (
    <div className="p-4 bg-gray-100 rounded-lg animate-pulse">
      {/* <div className="w-[59%]"> */}
      {/* Property Information Skeleton */}
      <div className="flex justify-between">
        <div className="w-[59%]">
          <div className="bg-white p-6 rounded-lg shadow-md flex">
            <div className="w-[30%]">
              <div className="bg-gray-200 rounded-full w-[150px] h-[150px] mb-4"></div>
            </div>
            <div className="w-[70%] flex flex-col justify-center">
              <div className="h-6 bg-gray-200 rounded w-2/3 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-4/6"></div>
            </div>
          </div>
        </div>
        <div className="w-[39%]">
          <div className="bg-white p-6 rounded-lg shadow-md flex py-4">
            <div className="h-[180px] bg-gray-200 rounded w-full"></div>
          </div>
        </div>
      </div>

      {/* Rental Property Lease Agreement Skeleton */}
      <div className="mt-6 bg-white p-6 rounded-lg shadow-md flex justify-between">
        <div className="h-28 bg-gray-200 rounded w-[23%] mb-4"></div>
        <div className="h-28 bg-gray-200 rounded w-[23%] mb-4"></div>
        <div className="h-28 bg-gray-200 rounded w-[23%] mb-4"></div>
        <div className="h-28 bg-gray-200 rounded w-[23%] mb-4"></div>
      </div>

      <div className="mt-6 bg-white p-6 rounded-lg shadow-md flex flex-wrap justify-between">
        {data.map((item, i) => (
          <div className=" bg-gray-200 mt-3 rounded w-[49%] px-2 flex">
            <div className="w-[80%] py-3">
              <div className="h-8 bg-gray-100 rounded w-[150px]"></div>
              <div className="h-12 bg-gray-100 rounded w-[300px] mt-3"></div>
            </div>
            <div className="w-[20%] flex items-center">
              <div className="h-16 bg-gray-100 rounded w-[90%]"></div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between mt-5">
        <div className="w-[49%] bg-white rounded-lg shadow-md px-3 py-5">
          <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-200 rounded w-full"></div>
        </div>
        <div className="w-[49%] bg-white rounded-lg shadow-md px-3 py-5">
          <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
          <div className="h-10 bg-gray-200 rounded w-full"></div>
        </div>
      </div>
      <div className=" bg-white rounded-lg shadow-md px-3 py-5 mt-5">
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full"></div>
      </div>
    </div>
  );
};

export default PropertyLoader;
