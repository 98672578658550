import React, { useRef, useState } from "react";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import moment from "moment";
import { ChevronDown, ChevronUp } from "lucide-react";
import { motion } from "framer-motion";
const MaintenanceSummryReports = ({ allData }) => {
  const tableRef = useRef(null);



  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const totalPages = Math.ceil(allData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = allData.slice(startIndex, startIndex + rowsPerPage);



  const downloadPDF = () => {
    toPng(tableRef.current, { quality: 1 })
      .then((dataUrl) => {
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.setFontSize(18);
        pdf.text("Maintenance Report", 105, 20, { align: "center" });

        const imgWidth = 190;
        const imgHeight =
          (tableRef.current.clientHeight * imgWidth) /
          tableRef.current.clientWidth;

        pdf.addImage(dataUrl, "PNG", 10, 30, imgWidth, imgHeight);
        pdf.save("Maintenance_Summary.pdf");
      })
      .catch((error) => console.error("Error generating PDF:", error));
  };

  return (
    <div className="w-full mt-5">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <button
          className="flex w-full justify-between items-center p-4 text-left"
          onClick={() => toggleAccordion(1)}
        >
          {/* <span className="text-lg font-medium">{item.title}</span> */}
          <div className="flex justify-between items-center ">
            <h2 className="text-xl text-darkColor font-popinsSemiBold">Maintenance Summary Report</h2>
          </div>
          <div className="flex items-center">
            <button
              className="px-4 py-2 mr-2 bg-themeColor text-white rounded-md font-popinsMedium"
              onClick={downloadPDF}
            >
              Download PDF
            </button>
            {activeIndex === 1 ? <ChevronUp /> : <ChevronDown />}
          </div>
        </button>


        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: activeIndex === 1 ? "auto" : 0,
            opacity: activeIndex === 1 ? 1 : 0,
          }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="overflow-hidden"
        >

          <div className="w-full">

            <div
              ref={tableRef}
              className="overflow-auto w-full p-4 bg-white " >
              <table className="w-full border-collapse border border-gray-200">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 p-2">Property Name</th>
                    <th className="border border-gray-300 p-2">Unit Name</th>
                    <th className="border border-gray-300 p-2">Caller Name</th>
                    <th className="border border-gray-300 p-2">Caller Number</th>
                    <th className="border border-gray-300 p-2">Request Date</th>
                    <th className="border border-gray-300 p-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={index} className="text-center">
                      <td className="border border-gray-300 p-2">{item?.propertyInfo?.propertyName}</td>
                      <td className="border border-gray-300 p-2">{item?.unitInfo?.unitName}</td>
                      <td className="border border-gray-300 p-2">{item.callerInformation.name}</td>
                      <td className="border border-gray-300 p-2">{item.callerInformation.number}</td>
                      <td className="border border-gray-300 p-2">{moment(item.requestDate).format("DD/MM/YYYY")}</td>
                      <td className="border border-gray-300 p-2">{item.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>

          </div>
        </motion.div>

      </div>



    </div>
    // <div className="w-full mt-5">
    //   <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
    //     <div className="flex justify-between items-center mb-4">
    //       <h2 className="text-xl font-semibold">Maintenance Summary Report</h2>
    //       <button
    //         className="px-4 py-2 bg-blue-500 text-white rounded-md"
    //         onClick={downloadPDF}
    //       >
    //         Download PDF
    //       </button>
    //     </div>
    //     <div ref={tableRef} className="overflow-auto">
    // <table className="w-full border-collapse border border-gray-200">
    //   <thead>
    //     <tr className="bg-gray-100">
    //       <th className="border border-gray-300 p-2">Property Name</th>
    //       <th className="border border-gray-300 p-2">Unit Name</th>
    //       <th className="border border-gray-300 p-2">Caller Name</th>
    //       <th className="border border-gray-300 p-2">Caller Number</th>
    //       <th className="border border-gray-300 p-2">Request Date</th>
    //       <th className="border border-gray-300 p-2">Status</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {currentData.map((item, index) => (
    //       <tr key={index} className="text-center">
    //         <td className="border border-gray-300 p-2">{item?.propertyInfo?.propertyName}</td>
    //         <td className="border border-gray-300 p-2">{item?.unitInfo?.unitName}</td>
    //         <td className="border border-gray-300 p-2">{item.callerInformation.name}</td>
    //         <td className="border border-gray-300 p-2">{item.callerInformation.number}</td>
    //         <td className="border border-gray-300 p-2">{moment(item.requestDate).format("DD/MM/YYYY")}</td>
    //         <td className="border border-gray-300 p-2">{item.status}</td>
    //       </tr>
    //     ))}
    //   </tbody>
    // </table>
    //     </div>

    //   </div>
    // </div>
  );
};

export default MaintenanceSummryReports;