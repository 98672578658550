import React, { useRef, useState } from "react";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import moment from "moment";
import { CircleX } from "lucide-react";

const LeaseSummaryReportPopup = ({
  allData,
  isOpen,
  onClose,
  PropertyName,
}) => {
  const tableRef = useRef(null);

  // Calculate Totals

  function calculatePercentageNumber(total, value) {
    if (total === 0) return 0;
    return (value / total) * 100;
  }

  const totalSF = allData?.reduce(
    (sum, t) =>
      sum +
      calculatePercentageNumber(t?.unitInfo?.size, t?.unitInfo?.tenantSize),
    0
  );
  // const totalMonthlyRent = allData?.reduce((sum, t) => sum + t?.monthlyRent, 0);

  const totalMonthlyRent = allData?.reduce((sum, item) => {
    const additionalCharges = item.additionalCharges?.reduce(
      (acc, charge) => acc + parseFloat(charge.amount || 0),
      0
    );
    return sum + additionalCharges;
  }, 0);

  //   const downloadPDF = () => {
  //     toPng(tableRef.current, { quality: 1 })
  //       .then((dataUrl) => {
  //         const pdf = new jsPDF("p", "mm", "a4");
  //         pdf.setFontSize(18);
  //         pdf.text(` Summary`, 105, 20, { align: "center" });
  //         const imgWidth = 190;
  //         const imgHeight =
  //           (tableRef.current.clientHeight * imgWidth) /
  //           tableRef.current.clientWidth;

  //         pdf.addImage(dataUrl, "PNG", 10, 10, imgWidth, imgHeight);
  //         pdf.save(`${PropertyName}'s_Summary.pdf`);
  //       })
  //       .catch((error) => {
  //         console.error("Error generating PDF:", error);
  //       });
  //   };

  const downloadPDF = () => {
    toPng(tableRef.current, { quality: 1 })
      .then((dataUrl) => {
        const pdf = new jsPDF("p", "mm", "a4");

        // Heading add karna
        pdf.setFontSize(18);
        pdf.text(`${PropertyName} Summary`, 105, 20, { align: "center" });

        const imgWidth = 190;
        const imgHeight =
          (tableRef.current.clientHeight * imgWidth) /
          tableRef.current.clientWidth;

        pdf.addImage(dataUrl, "PNG", 10, 30, imgWidth, imgHeight);
        pdf.save(`${PropertyName}'s_Summary.pdf`);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };
  function calculatePercentage(total, value) {
    if (total === 0) return 0;
    return `${(value / total) * 100}%`;
  }

  function calculateRentPerSF(rent, totalSF) {
    if (totalSF === 0) return 0;
    return rent / totalSF;
  }

  function calculateTotalAmount(items) {
    return items?.reduce((total, item) => total + parseFloat(item.amount), 0);
  }

  function getTotalSF(leaseList) {
    return leaseList?.reduce(
      (total, lease) => total + (lease.unitInfo?.tenantSize || 0),
      0
    );
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg w-11/12 md:w-3/4 lg:w-2/3 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-popinsSemiBold">{`${PropertyName} Summary`}</h2>
          <CircleX size={22} className="cursor-pointer" onClick={onClose} />

        </div>

        <div ref={tableRef} className="overflow-auto p-4 bg-white">
          <table className="w-full shadow-sm text-sm border-2 rounded-md border-gray-100">
            <thead>
              <tr className="border-b border-darkColor/20 text-center text-[13px] text-darkColor/40">
                <th className="w-[11%] py-2">Tenant Name</th>
                <th className="w-[11%] py-2">Suite</th>
                <th className="w-[11%] py-2">SF</th>
                <th className="w-[11%] py-2">BLDG Share</th>
                <th className="w-[11%] py-2">Lease Comm.</th>
                <th className="w-[11%] py-2">Lease Exp.</th>
                <th className="w-[11%] py-2">Rent / SF</th>
                <th className="w-[11%] py-2">Per Month</th>
                <th className="w-[11%] py-2">Per Year</th>
              </tr>
            </thead>
            <tbody>
              {allData?.map((item, index) => {
                const TotalRentAmount = calculateTotalAmount(
                  item?.additionalCharges
                );
                return (
                  <tr
                    key={index}
                    className="font-popinsRegular even:bg-themeColor/20 text-center"
                  >
                    <td className="py-2">{`${item?.tenantInfo?.firstName} ${item?.tenantInfo?.lastName}`}</td>
                    <td className="py-2">{item?.unitInfo?.unitName}</td>
                    <td className="py-2">{item?.unitInfo?.tenantSize}</td>
                    <td className="py-2">
                      {calculatePercentage(
                        item?.unitInfo?.size,
                        item?.unitInfo?.tenantSize
                      )}
                    </td>
                    <td className="py-2">
                      {moment(item?.startDate).format("DD/MM/YY")}
                    </td>
                    <td className="py-2">
                      {moment(item?.endDate).format("DD/MM/YY")}
                    </td>
                    <td className="py-2">
                      $
                      {calculateRentPerSF(
                        TotalRentAmount,
                        item?.unitInfo?.tenantSize
                      )?.toFixed(2)}
                    </td>
                    <td className="py-2">
                      ${TotalRentAmount?.toLocaleString()}
                    </td>
                    <td className="py-2">
                      {item?.unitInfo?.rentType === "Yearly"
                        ? `$${TotalRentAmount * 12}`
                        : item?.unitInfo?.rentType}
                    </td>
                  </tr>
                );
              })}

              <tr className="bg-themeColor text-white font-popinsBold text-center text-base">
                <td className="py-2">Total</td>
                <td className="py-2"></td>
                <td className="py-2">{`${getTotalSF(allData)}`}</td>
                <td className="py-2">{`${totalSF}%`}</td>
                <td className="py-2"></td>
                <td className="py-2"></td>
                <td className="py-2"></td>
                <td className="py-2">${totalMonthlyRent}</td>
                <td className="py-2">{ }</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-center">
          <button
            className="px-4 py-2 bg-themeColor text-white rounded-md font-popinsMedium"
            onClick={downloadPDF}
          >
            Download PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaseSummaryReportPopup;
