import React from "react";
import ParagraphTag from "../reusebale/Paragraph";
import LabelTag from "../reusebale/label";
import ButtonTag from "../reusebale/button";

const TutorialPopup = ({ onFormSubmit, onBack }) => {
  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[30%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col">
          <div>
            <ParagraphTag
              content="Create New"
              classes={`text-darkColor font-popinsSemiBold text-base `}
            />
          </div>
          <div className="pt-2 w-[90%] mx-auto">
            <LabelTag
              isStaric={false}
              name="Title"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              disabled
              type="text"
              placeholder="Enter your title.."
              className="w-full mt-1 font-popinsRegular bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 pr-16"
            />
          </div>
          <div className="pt-2 w-[90%] mx-auto">
            <LabelTag
              isStaric={false}
              name="Link"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              disabled
              type="text"
              placeholder="Enter your Link.."
              className="w-full mt-1 font-popinsRegular bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 pr-16"
            />
          </div>
          <div className="pt-2 w-[90%] mx-auto">
            <LabelTag
              isStaric={false}
              name="User Role"
              classes="!text-xs !font-popinsMedium"
            />
            <select
              className="block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm bg-bgColor"
            >  
              <option>Admin</option>
              <option>Landlord</option>
              <option>Tenant</option>
              <option>Support</option>
            </select>
          </div>
          <div className="w-full px-2">
            <ButtonTag
              onSubmit={onFormSubmit}
              name="Submit"
              classes="text-sm bg-themeColor hover:bg-themeColor/90 mt-5 text-center  text-white"
            />
            <p
              className="text-darkColor pt-2 flex item-center justify-center cursor-pointer font-popinsMedium text-sm"
              onClick={onBack}
            >
              Close
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TutorialPopup;
