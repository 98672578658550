import React, { useState } from "react";
import Breadcrumbs from "../../../reusebale/breadcrumbs"
import PersonalForm from "../personal-information/personal-form";
import UploadImage from "../personal-information/upload-image";
import { useSelector, useDispatch } from "react-redux";
import BaseUrl from "../../../../constants/BaseUrl";
import { ShowError } from "../../../../utils/ToasFunction";


const PersonalInfo = ({ onBack, clases }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const [loading, setLoading] = useState(false)


  const UploadImageToServerImage = (file) => {
    setLoading(true)
    const formdata = new FormData();
    formdata.append("image", file);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/uploads/upload`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.url) {
          setLoading(false)
          setImageUrl(result.url)
        } else {
          ShowError("Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB")
        }
      })
      .catch((error) => {
        setLoading(false)
        ShowError("Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB")
      });
  }

  const { loginSuccess, userCompleteProfile } = useSelector((state) => state.auth)
  return (
    <div className={`w-full ${clases}`}>
      <Breadcrumbs recordId={`${loginSuccess?.user?.firstName} ${loginSuccess?.user?.lastName}`} subheading='My Account' onBack={onBack} />
      <div className="flex justify-between mt-8">
        <div className="w-[64%]">
          <PersonalForm uploadedImageUrl={imageUrl} />
        </div>
        <div className="w-[34%]">
          <UploadImage
            loading={loading}
            imageUploadFunction={(imagePath) => {
              UploadImageToServerImage(imagePath)
            }}
            imagePath={imageUrl ? imageUrl : userCompleteProfile?.personalInformation?.profileImage}
            imageTitle="Profile Image"
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
