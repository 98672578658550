import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class ApexChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [0], // Start animation from 0
      options: {
        chart: {
          height: 250,
          type: "radialBar",
          animations: {
            enabled: false, // Disable internal animation (handled manually)
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },
            track: {
              background: "#e7e7e7",
              strokeWidth: "100%",
              dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 4,
                opacity: 0.1,
              },
            },
            dataLabels: {
              name: {
                show: true,
                color: "rgba(0, 0, 0, 0.3)",
                fontSize: "14px",
                fontWeight: "600",
                offsetY: 20,
              },
              value: {
                show: true,
                color: "rgba(0, 0, 0)",
                fontSize: "22px",
                fontWeight: "700",
                offsetY: -20,
              },
            },
            startAngle: -90,
            endAngle: 270,
            strokeLinecap: "round",
          },
        },
        fill: {
          colors: ["#5267ED"],
        },
        labels: [props.label || "Vacant"],
      },
    };
  }

  componentDidMount() {
    this.animateCounter(this.props.vacantValue || 0); // Start animation
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vacantValue !== this.props.vacantValue) {
      this.animateCounter(this.props.vacantValue);
    }
  }

  animateCounter(targetValue) {
    let currentValue = 0;
    const increment = targetValue / 60; // Smooth animation steps
    const interval = setInterval(() => {
      if (currentValue >= targetValue) {
        clearInterval(interval);
        this.setState({ series: [targetValue] }); // Ensure final value is exact
      } else {
        currentValue += increment;
        this.setState({ series: [Math.round(currentValue)] });
      }
    }, 20);
  }

  render() {
    return (
      <div>
        <ReactApexChart
          options={{ ...this.state.options, labels: [this.props.label || "Vacant"] }} 
          series={this.state.series}
          type="radialBar"
          height={230}
        />
      </div>
    );
  }
}

export default ApexChart;
