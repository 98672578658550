import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ReactPaginate from "react-paginate";
import NoData from "../../reusebale/noData";
import Myloader from "../../reusebale/myLoader";
import {
  ArrowUpDown,
  ArrowDownUp,
  SquareCheck,
  SquareX,
  ArrowRight,
} from "lucide-react";
import db from "../../../db/index.json";
import BaseUrl from "../../../constants/BaseUrl";
import { useSelector } from "react-redux";
import ButtonTag from "../../reusebale/button";
import LeaseTableLoader from "../../skeleton-loader/lease-table"

const TenantsTable = ({ onViewDetails, selectedTenant, search }) => {
  const { loginSuccess } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { tenants } = db;

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const paginatedData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  const GetAllTenants = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}tenant/getAllTenantByLanlordId/${loginSuccess.user._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setData(result.data);
          setLoading(false);
          //   {
          //     "_id": "6772cb94efd1a264ad6572d4",
          //     "firstName": "Micheal",
          //     "landlordId": "6772c88c637dea6c180a8083",
          //     "lastName": "Teran",
          //     "email": "micheal@gmail.com",
          //     "emailVerified": false,
          //     "cellNo": "923162565565",
          //     "cellNoVerified": false,
          //     "password": "112233",
          //     "role": "tenant",
          //     "createdAt": "2024-12-30T16:34:28.898Z",
          //     "updatedAt": "2024-12-30T16:34:28.898Z",
          //     "__v": 0
          // }
        } else {
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetAllTenants();
  }, []);

  return (
    <div className="h-full">
      <div className="bg-white w-full h-[87%] ">
        <div className="w-[95%] mx-auto">
          <div className="flex items-center h-[12%] justify-between">
            <ParagraphTag
              content={tenants.heading}
              classes="text-darkColor font-popinsSemiBold text-lg py-5"
            />


          </div>
          <div className="overflow-x-auto w-full h-[88%] panelScroll pt-2">
            <table className="w-full ">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th className="w-[23%] text-left">
                    <span className="flex">
                      <ParagraphTag
                        content={tenants.heading}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "lease" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("property")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("property")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("property")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[17%]">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={tenants.email}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "email" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("email")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("email")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("email")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[15%]">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={tenants.emailVerify}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "emailVerify" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("emailVerify")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("emailVerify")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("emailVerify")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[15%]">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={tenants.phone}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "phone" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("phone")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("phone")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("phone")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[15%]">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={tenants.phoneVerify}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "phoneVerify" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("phoneVerify")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("phoneVerify")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("phoneVerify")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[15%] text-right pr-4">
                    <ParagraphTag
                      content={tenants.actions}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item) => {
                  if (`${item.firstName} ${item.lastName}`.includes(search)) {
                    return (
                      <tr key={item.id} className="hover:bg-gray-100 rounded-md">
                        <td className="py-1">
                          <div className="flex items-center justify-between">
                            <div className="w-full">
                              <ParagraphTag
                                content={`TEN-${item?._id?.slice(0, 10)}`}
                                classes="text-darkColor/50 font-popinsMedium text-xs"
                              />
                              <ParagraphTag
                                content={`${item.firstName} ${item.lastName}`}
                                classes="text-darkColor font-popinsSemiBold text-sm"
                              />
                            </div>
                          </div>
                        </td>
                        <td className="py-1 font-popinsMedium text-darkColor text-sm">
                          <ParagraphTag
                            content={`${item.email}`}
                            classes="text-darkColor font-popinsSemiBold text-sm"
                          />
                        </td>
                        <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                          <div className="flex justify-center items-center">
                            {(() => {
                              switch (item.emailVerified) {
                                case true:
                                  return <SquareCheck color="#01CB78" size={20} />; // Green check for true
                                case false:
                                  return <SquareX color="#E43B3B" size={20} />; // Red cross for false
                                default:
                                  return null; // In case `emailVerified` is undefined or null
                              }
                            })()}
                          </div>
                        </td>
                        <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                          {item.cellNo}
                        </td>
                        <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                          <div className="flex justify-center items-center">
                            {(() => {
                              switch (item.cellNoVerified) {
                                case true:
                                  return <SquareCheck color="#01CB78" size={20} />; // Green check for true
                                case false:
                                  return <SquareX color="#E43B3B" size={20} />; // Red cross for false
                                default:
                                  return null;
                              }
                            })()}
                          </div>
                        </td>
                        <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm">
                          <div className="flex justify-end pr-3">
                            <div
                              className="flex justify-center items-center border border-themeColor w-7 h-7 rounded-md cursor-pointer"
                              onClick={() => {
                                onViewDetails(item);
                              }}
                            >
                              <ArrowRight
                                color="#6F6F6F"
                                size={18}
                                strokeWidth={2}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  }

                })}
              </tbody>
            </table>
            {loading && (
                <LeaseTableLoader/>
            )}
            {paginatedData.length === 0 && !loading && (
              <div className="w-full flex justify-center mt-5">
                <NoData />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center h-[13%]">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          pageCount={Math.ceil(data.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName="flex space-x-2 items-center"
          pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
          previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
          nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
          disabledClassName="opacity-50 !cursor-not-allowed"
          activeClassName="bg-themeColor text-white"
          breakClassName="px-3 py-1 text-sm font-popinsMedium"
        />
      </div>
    </div>
  );
};

export default TenantsTable;
