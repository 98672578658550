import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import { ChevronDown } from 'lucide-react'
import ApexChart from './dashborad-charts/pieChart'
import BaseUrl from "../../../constants/BaseUrl";
import { useSelector } from "react-redux"
const OccupancyRate = () => {
  const { rightPanel } = db;

  const { loader, loginSuccess, loginError } = useSelector(
    (state) => state.auth
  );


  const [stats, setStats] = useState([])
  const [selectedStat, setSelectedStat] = useState()

  const GetPropertyStats = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/property/getPropertyOccupancyStats/${loginSuccess.user._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setStats(result.data)
          setSelectedStat(result.data[0])
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    GetPropertyStats()
  }, [])



  function PercentageToNumber(percentageStr) {
    return parseFloat(percentageStr.replace('%', ''));
  }


  return (
    <div className="bg-white rounded-2xl pt-5 px-4 w-full mt-5 flex shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="w-[50%]">
        <ParagraphTag
          content={rightPanel.occupancy}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />

        <div className="w-full relative group mt-4">
          <div className="bg-[#F4F4F4] flex px-3 py-3 justify-between items-center rounded-xl cursor-pointer">
            <p className="text-darkColor font-popinsRegular text-sm">{selectedStat?.propertyName}</p>
            <div className="flex justify-center items-center bg-themeColor px-[6px] py-1 rounded-md cursor-pointer">
              <ChevronDown color="#FFFFFF" size={18} strokeWidth={3} />
            </div>
          </div>
          <div className="bg-white shadow-xl py-3 hidden group-hover:flex group-hover:flex-col border border-white/20 rounded-xl w-full right-0 top-[53px] absolute">
            {stats.map((item, i) => (
              <p onClick={() => {
                setSelectedStat(item)
              }} className="text-darkColor font-popinsRegular cursor-pointer text-sm pt-4 pl-4 border-b-[1px] border-darkColor/20 pb-2">{item?.propertyName}</p>
            ))}
          </div>
        </div>

        <div className="flex pt-8">
          <div className="w-[50%]">
            <ParagraphTag
              content={rightPanel.vacant}
              classes="text-darkColor/40 font-popinsMedium text-base"
            />
            <ParagraphTag
              content={`${selectedStat ? selectedStat?.availablePercentage : "0.00%"}`}
              classes="text-darkColor font-popinsBold text-[22px]"
            />
          </div>
          <div className="w-[50%]">
            <ParagraphTag
              content={rightPanel.occupied}
              classes="text-darkColor/40 font-popinsMedium text-base"
            />
            <ParagraphTag
              content={`${selectedStat ? selectedStat?.occupiedPercentage : "0.00%"}`}
              classes="text-darkColor font-popinsBold text-[22px]"
            />
          </div>
        </div>
      </div>
      <div className="w-[50%] flex justify-center">
        <ApexChart vacantValue={selectedStat ? Number(PercentageToNumber(selectedStat?.availablePercentage)) : 0} label={rightPanel.vacant} />
      </div>
    </div>
  );
};

export default OccupancyRate;
