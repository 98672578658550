// DashboardChart.jsx
import React, {
  useState,
  useEffect
} from "react";
import HorizonatlBarChart from "./dashborad-charts/horizonatalBar"; // Import HorizontalChart component
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import BaseUrl from "../../../constants/BaseUrl"
import { useSelector } from "react-redux"
const RenewelSection = () => {
  const { rightPanel } = db;
  const { loader, loginSuccess, loginError } = useSelector(
    (state) => state.auth
  );

  const [allStats, setAllStats] = useState()
  const [allLabels, setAllLabels] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [allValues, setAllValues] = useState([])

  const GetAllCount = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/maintenence/getMaintenanceStatusCount/${loginSuccess.user._id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllStats(result?.data)
          setTotalCount(result.totalCount)
          setAllLabels(result.data.map(item => item.status))
          setAllValues(result.data.map(item => item.count))
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    GetAllCount()
  }, [])

  return (
    <div className="dashboard-chart-container bg-white rounded-2xl mt-5 py-5 px-4 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="">
        <ParagraphTag
          content={"Maintenance Statistics"}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />

        <ParagraphTag
          content={`Total Requets: ${totalCount}`}
          classes="text-darkColor font-popinsSemiBold text-xl pt-5"
        />

      </div>



      {/* Include HorizontalChart here */}
      <div className="mt-8">
        <HorizonatlBarChart
          categories={allLabels}
          values={allValues}
        />
      </div>
    </div>
  );
};

export default RenewelSection;
