import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";
import LabelTag from "../../../reusebale/label";
import { Plus, Trash } from "lucide-react";
import db from "../../../../db/index.json";
import IconCards from "../../../reusebale/iconCards";
import * as LeaseAction from "../../../../store/actions/lease/LeaseAction";
import { useDispatch, useSelector } from "react-redux";
import { ShowError } from "../../../../utils/ToasFunction";
const RentCharges = ({
  formData,
  onRentDateChange,
  onRentFrequencyChange,
  isChargeLate,
  onLatePercentageChange,
  onLateGraceDays,
  onAdditionalCharges,
  onItemDeleteChanges
}) => {

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined date
    return dateString.split("T")[0]; // Extracts the date part
  };

  const dispatch = useDispatch();

  const [additionalChargesList, setAdditionalList] = useState({
    title: "",
    description: "",
    amount: "",
  });

  const RentRuctureList = () => {
    const data = formData.additionalCharges;


    return (
      <div className="w-full border-white !rounded-xl pb-2 mb-5">
        <table className="w-full border-2 rounded-md border-gray-100 shadow-sm">
          <thead className="">
            <tr className="!font-popinsRegular border-b border-black/20 text-darkColor/40 text-[13px]">
              <th className="py-2 pl-4 text-left w-[27%]">Title</th>
              <th className="py-2 text-left w-[48%]">Description</th>
              <th className="py-2 pl-3 text-left w-[10%]">Amount</th>
              <th className="py-2 pr-3 text-right w-[15%]">Quick Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.id} className=" even:bg-themeColor/40 font-popinsMedium text-darkColor text-xs">
                <td className="pl-4 py-1">{row?.title}</td>
                <td className="py-1 pr-3">
                  <span className="line-clamp-1">{row?.description}</span>
                </td>
                <td className="py-1 pl-3">{`$ ${row?.amount}`}</td>
                <td className="py-1">
                  <div className="flex justify-end mr-8">
                    <div className="bg-[#FF814E] cursor-pointer w-8 h-8 rounded-md flex items-center justify-center">
                      <Trash onClick={() => {
                        onItemDeleteChanges(row)
                      }} size={16} color="#FFFF" />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="w-[95%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Rent Charges"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Lorem Ipsum"
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="flex justify-between w-[65%] mx-auto">
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="Rent Date"
              classes="!text-xs !font-popinsMedium"
            />

            <input
              value={formatDate(formData.rentStartDate)}
              onChange={onRentDateChange}
              type="date"
              placeholder="Type"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[49%]">
            <LabelTag
              name="Frequency"
              isStaric={false}
              className="text-darkColor text-left !text-xs !font-popinsMedium"
              classes="!text-xs !font-popinsMedium"
            />
            <select
              onChange={onRentFrequencyChange}
              className="block w-full bg-bgColor px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm mt-1"
            >
              <option>Monthly</option>
              <option>Yearly</option>
              <option>One Time</option>
            </select>
          </div>
        </div>
        <div className="text-center pt-6">
          <ParagraphTag
            content="Charge Late Fee?"
            classes="text-darkColor font-popinsSemiBold text-base"
          />
          <ParagraphTag
            content="Will you be charge late fee on this lease?"
            classes="text-darkColor/50 font-popinsMedium text-xs"
          />
        </div>


        <div className="flex w-[30%] mx-auto justify-between mt-4">
          <div
            key={"Yes"}
            className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
            onClick={(e) => {
              e.preventDefault();
              isChargeLate(true);
            }}
          >
            <IconCards
              mainClasses={`h-36 w-[130px] ${formData?.chargeLate ? "border-red-500 border-2" : ""
                }`}
              imgClasses="w-[40px]"
              path={
                "/assets/daashboard/create-propety/check.png"
              }
              title={"Yes"}
              titleClasses={
                "text-themeColor"
              }
            />
          </div>

          <div
            key={"No"}
            className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
            onClick={(e) => {
              e.preventDefault();
              isChargeLate(false);
            }}
          >
            <IconCards
              mainClasses={`h-36 w-[130px] ${!formData?.chargeLate ? "border-red-500 border-2" : ""
                }`}
              imgClasses="w-[40px]"
              path={
                "/assets/daashboard/create-propety/close.png"
              }
              title={"No"}
              titleClasses={
                "text-themeColor"
              }
            />
          </div>
          {/* {Funds?.map((item) => (
            <div
              key={item.title}
              className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
              onClick={() => {
                isChargeLate(item.title);
              }}
            >
              <IconCards
                mainClasses={`h-36 w-[130px] ${formData?.chargeLate ? "border-red-500 border-2" : ""
                  }`}
                imgClasses="w-[40px]"
                path={
                  formData?.chargeLate ? item.imagePathActiv : item.imagePath
                }
                title={item.title}
                titleClasses={
                  formData?.chargeLate ? "text-themeColor" : "text-darkColor"
                }
              />
            </div>
          ))} */}
        </div>
        {formData?.chargeLate && (
          <div className="flex justify-between pt-3 w-[65%] mx-auto">
            <div className="w-[49%]">
              <LabelTag
                isStaric={false}
                name="Percentage"
                classes="!text-xs !font-popinsMedium"
              />
              <input
                value={formData.latePercentage}
                onChange={onLatePercentageChange}
                type="text"
                placeholder="Type"
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
            <div className="w-[49%]">
              <LabelTag
                name="Number of Days"
                isStaric={false}
                className="text-darkColor text-left !text-xs !font-popinsMedium"
                classes="!text-xs !font-popinsMedium"
              />
              <input
                value={formData.graceDays}
                onChange={onLateGraceDays}
                type="number"
                placeholder="Type"
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
          </div>
        )}
        <div className="h-[1px] bg-darkColor/20 mt-7"></div>
        <div className="w-[85%] mx-auto flex justify-between mt-5">
          <div className="w-[5%] flex items-center pt-4">
            <ParagraphTag
              content=""
              classes={`text-themeColor font-popinsSemiBold pt-1 text-xs`}
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="Charges"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              value={additionalChargesList.title}
              onChange={(e) => {
                setAdditionalList({
                  ...additionalChargesList,
                  title: e.target.value,
                });
              }}
              type="text"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="Description"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              value={additionalChargesList.description}
              onChange={(e) => {
                setAdditionalList({
                  ...additionalChargesList,
                  description: e.target.value,
                });
              }}
              type="text"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="Amount"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              value={additionalChargesList.amount}
              onChange={(e) => {
                setAdditionalList({
                  ...additionalChargesList,
                  amount: e.target.value,
                });
              }}
              type="number"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          {/* <div className="flex justify-end items-end pb-1 cursor-pointer w-[6%]">
            <div className="border-2 border-dangerColor w-7 h-7 rounded-md flex justify-center items-center">
              <Trash color="#E43B3B" size={16} />
            </div>
          </div> */}
        </div>
        <div className="flex w-full justify-between items-center py-5">
          <div className="w-[36%] h-[1px] bg-darkColor/20"></div>
          <div className="w-[25%]">
            <button
              onClick={(e) => {
                e.preventDefault();
                if (additionalChargesList.title === "") {
                  ShowError("Title is required");
                } else if (additionalChargesList.amount === "") {
                  ShowError("Amount is required");
                } else {
                  onAdditionalCharges(additionalChargesList);
                  setAdditionalList({
                    ...additionalChargesList,
                    title: "",
                    description: "",
                    amount: "",
                  });
                }
              }}
              className="flex justify-center items-center text-themeColor rounded-md border border-themeColor py-2 w-full text-xs font-popinsMedium"
            >
              <Plus size={15} color="#0381CA" />
              <span className="pl-2">Add New Charge</span>
            </button>
          </div>

          <div className="w-[36%] h-[1px] bg-darkColor/20"></div>
        </div>
        {
          formData?.additionalCharges?.length !== 0 && <RentRuctureList />
        }

      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={() => {
              dispatch(LeaseAction.ChangePropertyCreateSteps(4));
            }}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default RentCharges;
