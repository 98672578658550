import { useState } from "react";

const TenantLoader = () => {
  const [data, setData] = useState([1, 2, 3, 4, 5]);
  return (
    <div className="p-4 bg-gray-100 rounded-lg animate-pulse">
      {/* <div className="w-[59%]"> */}
      {/* Property Information Skeleton */}
      <div className="flex justify-between">
        <div className="w-[49%]">
          <div className="bg-white p-6 rounded-lg shadow-md flex">
            <div className="w-[30%]">
              <div className="bg-gray-200 rounded-full w-[150px] h-[150px] mb-4"></div>
            </div>
            <div className="w-[70%] flex flex-col justify-center">
              <div className="h-6 bg-gray-200 rounded w-2/3 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-4/6"></div>
            </div>
          </div>
        </div>
        <div className="w-[49%]">
          <div className="bg-white p-6 rounded-lg shadow-md flex">
            <div className="w-[30%]">
              <div className="bg-gray-200 rounded-full w-[150px] h-[150px] mb-4"></div>
            </div>
            <div className="w-[70%] flex flex-col justify-center">
              <div className="h-6 bg-gray-200 rounded w-2/3 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-4/6"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Rental Property Lease Agreement Skeleton */}
      <div className="mt-6 bg-white p-6 rounded-lg shadow-md">
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
      </div>

      <div className="mt-6 bg-white p-6 rounded-lg shadow-md">
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
      </div>

      <div className="flex flex-wrap my-5" id="gk-cards">
        {data.map((item, i) => (
          <div className="bg-white p-6 rounded-lg shadow-md flex w-[32%] mr-[2%] mt-3" >
            <div className="w-[30%]">
              <div className="bg-gray-200 rounded-full w-[80px] h-[80px] mb-4"></div>
            </div>
            <div className="w-[55%] flex flex-col justify-center">
              <div className="h-6 bg-gray-200 rounded w-full mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-[70%] mb-2"></div>
            </div>
            <div className="w-[15%] flex justify-end items-end">

              <div className="h-8 bg-gray-200 rounded w-10"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TenantLoader;
