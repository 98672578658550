import React from "react";
import ReactApexChart from "react-apexcharts";

class HorizonatlBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [{ data: props.values || [] }],
      options: {
        chart: {
          type: "bar",
          height: 350,
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 3000,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            horizontal: true,
            distributed: true,
            barHeight: "50%",
          },
        },
        colors: ["#4C6EF5", "#37D67A", "#FF4D4D", "#FFA726"],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000"],
            fontSize: "14px",
          },
          offsetX: 5,
        },
        xaxis: {
          categories: props.categories || [], // Labels for bars
          labels: {
            style: {
              colors: "#666",
              fontSize: "14px",
            },
          },
        },
        grid: {
          borderColor: "#e0e0e0",
        },
        tooltip: {
          enabled: true,
          theme: "light",
        },
        fill: {
          type: "solid",
        },
        stroke: {
          show: false,
        },
      },
    };
  }

  // Update state when props change
  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories || prevProps.values !== this.props.values) {
      this.setState({
        series: [{ data: this.props.values || [] }],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.props.categories || [],
          },
        },
      });
    }
  }

  render() {
    return (
      <div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default HorizonatlBarChart;
