import React, { useState } from "react";
import db from "../../../db/index.json";
import SearchBar from "../../reusebale/searchBar";
import LeasesTable from "../leases/leaseTable";
import FilterPopup from "../../popup/filterPopup";
import DetailLeaseMain from "./leaseDetail/main";
import CreateMain from "./createProperty/createMain";
import DetailMain from '../maintenance/maintanance-detail/detailMain'
import * as LeaseDataAction from "../../../store/actions/lease/LeaseAction"
import { useDispatch } from "react-redux"
const LeasesSection = ({ toggleMaintainanceTable, onTriggerCard }) => {
  const { lease } = db;
  const [showFilter, setShowFilter] = useState(false);
  const [showTable, setShowTable] = useState("table");
  const [showDetail, setShowDetail] = useState("");
  const [recordId, setRecordId] = useState();
  const [recordMainId, setRecordMainId] = useState();
  const dispatch = useDispatch()
  const toggleDetailMaintain = () => {
    setShowTable("MaintainanceDetails");
  };

  const openPopup = () => {
    setShowFilter(true);
  };

  const closePopup = () => {
    setShowFilter(false);
  };

  const toggleDetail = (status, id) => {
    setShowDetail(status);
    setRecordId(id);
    localStorage.setItem('leaseid', id);
    localStorage.setItem('leasestatus', status);
    setShowTable("detail"); // State toggle karein
  };

  const toggleDetailLease = () => {
    const id = localStorage.getItem('leaseid');
    const status = localStorage.getItem('leasestatus');
    setRecordId(id)
    setShowDetail(status)
    setShowTable("detail");
  }

  const toggleCreate = () => {
    setShowTable("create");
  };

  const toggleTable = () => {
    setShowTable("table");
  };



  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("all")
  return (
    <div>
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div>
                <div className="h-[10vh]">
                  <SearchBar
                    filterShow={true}
                    value={search}
                    onSearchChange={(e) => {
                      setSearch(e.target.value)
                    }}
                    title={lease.title}
                    desc={lease.description}
                    onSubmit={openPopup}
                  />
                </div>
                <div className="h-[90vh]">
                  <LeasesTable
                    status={status}
                    search={search}
                    isViewDetails={toggleDetail}
                    onViewCreate={toggleCreate}
                  />
                </div>
              </div>
            );
          case "detail":
            return (
              <DetailLeaseMain
                onBack={toggleTable}
                onEditLease={(leaseDetails) => {
                  toggleCreate()
                  dispatch(LeaseDataAction.LeaseDetailAction(leaseDetails))
                }}
                showDetailStatus={showDetail}
                recordId={recordId}
                onViewDetailsMaintain={(item) => {
                  setRecordMainId(item);
                  toggleDetailMaintain(item);
                }}
                onMaintainancePage={toggleMaintainanceTable}
                onTriggerCard={onTriggerCard}
              />
            );
          case "create":
            return <CreateMain onBack={toggleTable} />;
          case "MaintainanceDetails":
            return (
              <DetailMain
                recordId={recordMainId._id}
                onBack={toggleDetailLease}
              />
            );
          default:
            break;
        }
      })()}
      {showFilter && <FilterPopup onBack={closePopup} onFilter={(propertyNamse, Statuss) => {
        setSearch(propertyNamse)
        setStatus(Statuss)
        closePopup()
      }} />}
    </div>
  );
};

export default LeasesSection;
