import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { MessageCircle, X, Send } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import * as DataAction from "../../store/actions/data/DataAction";
import BaseUrl from "../../constants/BaseUrl";
import { ShowError, ShowSuccess } from "../../utils/ToasFunction";
function Card({ children }) {
  return (
    <div className="bg-white shadow-lg rounded-xl overflow-hidden">
      {children}
    </div>
  );
}

function Button({ children, onClick }) {
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      className="bg-[#0381CA] text-white p-3 rounded-full shadow-xl hover:shadow-2xl focus:outline-none"
    >
      {children}
    </motion.button>
  );
}

export default function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [suggestMsg, setSuggestMsg] = useState([
    {
      "msg": "📄 How can I track lease expiration dates and renewals?"
    },
    {
      "msg": "💰 How do I set up automated rent collection and invoicing?"
    },
    {
      "msg": "📝 Can I store and retrieve lease agreements digitally?"
    },
    {
      "msg": "📊 How do I generate rent roll and financial reports?"
    },
    {
      "msg": "💳 Does the system support online rent payments and late fee automation?"
    },
    {
      "msg": "💸 How can I track security deposits and refunds?"
    },
    {
      "msg": "🔧 How do I create and track maintenance requests?"
    },
    {
      "msg": "📅 Can tenants submit service requests online?"
    },
    {
      "msg": "📈 Can I generate occupancy and vacancy reports?"
    },
    {
      "msg": "💼 How do I track property performance metrics?"
    }
  ]);
  const messagesEndRef = useRef(null); // Reference for scrolling to the bottom
  const { loginSuccess, profileCompleted, userCompleteProfile } = useSelector(
    (state) => state.auth
  );
  const { currentActiveSession } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const sendMessage = () => {
    if (input.trim() === "") return;

    // Display user message immediately
    const userMessage = { text: input, type: "user" };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput(""); // Clear the input field

    // Display bot response immediately after user message
    // const botMessage = { text: "I'm here to help!", type: "bot" };
    // setMessages(prevMessages => [...prevMessages, botMessage]);
  };

  // Scroll to the bottom whenever messages are updated
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Scroll to the bottom when the chat is opened
  useEffect(() => {
    if (isOpen && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isOpen]);

  const GetUpdateChat = () => {
    const currentData = new Date();
    const formattedDate = currentData?.toISOString()?.split("T")[0];
    dispatch(
      DataAction.CurrentActiveChatSession(
        userCompleteProfile?.personalInformation?._id,
        formattedDate
      )
    );
  };

  useEffect(() => {
    GetUpdateChat();
  }, []);

  const UpdateChatMessage = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      chatObje: {
        id: userCompleteProfile?.personalInformation?._id,
        message: input,
        fullName: `${userCompleteProfile?.personalInformation?.firstName} ${userCompleteProfile?.personalInformation?.lastName}`,
        dateTime: new Date(),
        support: false,
      },
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/support/updateChatSession/${currentActiveSession?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "====");
        if (result.status === "Success") {
          GetUpdateChat();
          setInput("");
        } else {
          ShowError("Oops! something went wrong.");
        }
      })
      .catch((error) => console.error(error));
  };

  const CreateSupportSession = () => {
    const currentData = new Date();
    const formattedDate = currentData?.toISOString()?.split("T")[0];
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      customerData: userCompleteProfile.personalInformation,
      conversation: [
        {
          id: userCompleteProfile?.personalInformation?._id,
          message: input,
          fullName: `${userCompleteProfile?.personalInformation?.firstName} ${userCompleteProfile?.personalInformation?.lastName}`,
          dateTime: new Date(),
          support: false,
        },
      ],
      createDate: String(formattedDate),
    });

    console.log(raw, "-0-00-");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}api/support/createChatSession`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          ShowSuccess("Chat Session created successfully");
          GetUpdateChat();
          setInput("");
        } else {
          ShowError(result?.message);
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      GetUpdateChat();
    }, 3000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="fixed bottom-5 right-5 z-50 flex flex-col items-end">
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.4, ease: "easeOut" }}
          className="mb-4 w-[400px] shadow-2xl"
        >
          <button onClick={GetUpdateChat}>Update</button>
          <Card>
            <div className="flex items-center justify-between bg-[#0381CA] p-3 text-white">
              <span className="font-popinsBold">Chatbot</span>
              <motion.div whileHover={{ scale: 1.2 }}>
                <X
                  className="cursor-pointer"
                  onClick={() => setIsOpen(false)}
                />
              </motion.div>
            </div>
            <div className="flex flex-wrap h-40 overflow-y-auto panelScroll pr-4 border-b-2 border-black/10">
              {suggestMsg?.map((item, index) => (
                <div onClick={() => {
                  setInput(item.msg)
                }} className="bg-[#F4F4F4]  my-2 p-3 ml-4 text-black text-xs cursor-pointer font-popinsMedium self-end rounded-l-lg rounded-tl-lg rounded-br-lg">
                  <p>{item.msg}</p>
                </div>
              ))}
            </div>

            <div className="h-80 overflow-y-auto panelScroll p-4 bg-white flex flex-col space-y-3">
              {currentActiveSession?.conversation?.map((msg, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }} // Slight delay for smoother display
                  className={`p-3 max-w-[75%] shadow-md text-white font-popinsRegular ${!msg.support
                    ? "bg-[#0381CA] self-end rounded-l-lg rounded-tl-lg rounded-br-lg"
                    : "bg-gray-400 self-start rounded-r-lg rounded-tr-lg rounded-bl-lg"
                    }`}
                >
                  {msg?.message}
                </motion.div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="flex items-center border-t p-3 bg-gray-100">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type a message..."
                className="flex-1 p-2 font-popinsRegular border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#0381CA]"
              // onKeyPress={(e) => e.key === "Enter" && sendMessage()}
              />

              <motion.button
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  if (input === "") {
                    ShowError("Please enter any message first");
                  } else {
                    if (currentActiveSession) {
                      UpdateChatMessage();
                    } else {
                      CreateSupportSession();
                    }
                  }
                }}
                className="ml-3 text-[#0381CA] hover:text-[#0381CA]/90"
              >
                <Send size={28} />
              </motion.button>
            </div>
          </Card>
        </motion.div>
      )}
      <motion.div
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
        animate={{
          scale: isOpen ? 1.1 : 1,
          rotate: isOpen ? 45 : 0,
        }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20,
        }}
      >
        <Button onClick={() => setIsOpen(!isOpen)}>
          <MessageCircle size={26} />
        </Button>
      </motion.div>
    </div>
  );
}
