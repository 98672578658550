import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import Details from "./details";
import SuitableDate from "./suitableDate";
import CollerInfo from "./collerInfo";
import LeaveNotes from "./leaveNotes";
import Overview from "./overview";
import { useSelector, useDispatch } from "react-redux"
import * as DataAction from "../../../../store/actions/data/DataAction"
const CreateMaintainace = ({ onBack }) => {
  const steps = ["Details", "Collers", "Notes", "Overview"];
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(1);
  const MaintenanceCategories = useSelector((state) => state.data.maintenanceCategories)
  const maintenanceStepCount = useSelector((state) => state.data.maintenanceStepCount)

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  function getDateAfter10Days() {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 10);
    return futureDate;
  }


  const [formField, setFormFields] = useState({
    propertyInfo: null,
    unitInfo: null,
    description: "",
    requestCategory: { "id": 1, "category": "Electrical Issues", "requests": [{ "id": 101, "description": "Power outages" }, { "id": 102, "description": "Wiring and circuit issues" }, { "id": 103, "description": "Broken outlets or switches" }, { "id": 104, "description": "Lighting repairs" }, { "id": 105, "description": "Malfunctioning fans or air conditioning units" }] },
    requestType: null,
    typeList: [],
    requestDate: new Date(),
    dueDate: getDateAfter10Days(),
    proiority: null,
    permissionToEnter: false,
    callerName: "",
    callerPhone: "",
    callerEmail: "",
    techNote: "",
    accessNote: "",
    imageList: []
  })



  return (
    <div className="h-screen w-full">
      <div className="h-[10vh] flex flex-col justify-center pt-1">
        <ParagraphTag
          content="Maintenance Request"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Create new maintenance request"
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <div className="h-[90vh] py-3 w-full">
        <div className="bg-white rounded-md h-full">
          <div className="w-full mx-auto h-[15%]">
            {/* Stepper */}
            <div className="relative w-[70%] mx-auto flex items-center justify-between pt-5">
              {/* Step Circles */}
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center w-full relative"
                >
                  {/* Circle */}
                  <div
                    className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${maintenanceStepCount === index + 1
                      ? "border-[#0381CA] bg-white text-[#0381CA]"
                      : maintenanceStepCount > index + 1
                        ? "border-[#0381CA] bg-[#0381CA] text-white"
                        : "border-gray-300 bg-white text-gray-500"
                      } font-popinsMedium cursor-pointer transition-all`}
                    onClick={() => {
                      dispatch(DataAction.ChangeMaintenanceCurrentStep(index + 1))
                    }}
                  >
                    {index + 1}
                  </div>

                  {/* Step Label */}
                  <p
                    className={`mt-1 text-xs font-popinsRegular text-[#686868] ${maintenanceStepCount >= index + 1
                      ? "text-[#0381CA]"
                      : "text-gray-500"
                      }`}
                  >
                    {step}
                  </p>

                  {/* Line Connector */}
                  {index < steps.length - 1 && (
                    <div
                      className={`absolute top-4 left-[calc(50%+16px)] right-[calc(-50%+16px)] h-0.5 ${maintenanceStepCount > index + 1
                        ? "bg-[#0381CA]"
                        : "bg-gray-300"
                        }`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="h-[85%] w-full">
            {maintenanceStepCount === 1 && <Details
              imageList={(images) => {
                setFormFields({
                  ...formField,
                  imageList: images
                })
              }}
              formField={formField}
              onPropertySelect={(item) => {
                setFormFields({
                  ...formField,
                  propertyInfo: item
                })
              }}
              onUnitSelect={(item) => {
                setFormFields({
                  ...formField,
                  unitInfo: item
                })
              }}

              onDescriptionChange={(e) => {
                setFormFields({
                  ...formField,
                  description: e.target.value
                })
              }}

              onCategorySelect={(e) => {
                const value = e.target.value

                const categoryData = MaintenanceCategories.find((i) => i.category === value)


                setFormFields({
                  ...formField,
                  requestCategory: categoryData,
                  typeList: categoryData?.requests,
                  requestType: null

                })
              }}

              onTypeSelect={(e) => {
                const value = e.target.value
                const typeData = formField.typeList.find((i) => i.description === value)


                setFormFields({
                  ...formField,
                  requestType: typeData,

                })
              }}

              onDueDateChange={(e) => {
                const value = e.target.value
                setFormFields({
                  ...formField,
                  dueDate: value,

                })
              }}
            />}
            {/* {maintenanceStepCount === 2 && <SuitableDate formField={formField}
              onIntensityChange={(value) => {
                setFormFields({
                  ...formField,
                  proiority: value,

                })
              }}

              isPermissionChange={(value) => {
                setFormFields({
                  ...formField,
                  permissionToEnter: value,

                })
              }}

            />} */}
            {maintenanceStepCount === 2 && <CollerInfo formField={formField}
              onCollarNameChange={(e) => {
                const value = e.target.value
                setFormFields({
                  ...formField,
                  callerName: value,

                })
              }}

              onCollarNumberChange={(e) => {
                const value = e.target.value
                setFormFields({
                  ...formField,
                  callerPhone: value,

                })
              }}

              onCollarEmailChange={(e) => {
                const value = e.target.value
                setFormFields({
                  ...formField,
                  callerEmail: value,

                })
              }}

            />}
            {maintenanceStepCount === 3 && <LeaveNotes
              onTechNoteChange={(e) => {
                const value = e.target.value
                setFormFields({
                  ...formField,
                  techNote: value,

                })
              }}
              onAccNoteChange={(e) => {
                const value = e.target.value
                setFormFields({
                  ...formField,
                  accessNote: value,

                })
              }}
              formField={formField} />}
            {maintenanceStepCount === 4 && <Overview formField={formField} onClose={onBack} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMaintainace;
