import React, { useState } from "react";
import SearchBar from "../../reusebale/searchBar";
import db from "../../../db/index.json";
import LeaseTable from "../maintenance/leaseTable";
import FilterPopup from "../../popup/filterPopup";
import { useDispatch, useSelector } from "react-redux";
import CreateMaintainace from "../maintenance/create-maintainance/main";
import DetailMain from "../maintenance/maintanance-detail/detailMain";
import * as DataAction from "../../../store/actions/data/DataAction"
const MaintainanceSection = () => {
  const dispatch = useDispatch();
  const { maintainances } = db;
  const [showTable, setShowTable] = useState("table");

  const [showFilter, setShowFilter] = useState(false);
  const [recordId, setRecordId] = useState()

  const openPopup = () => {
    setShowFilter(true);
  };

  const closePopup = () => {
    setShowFilter(false);
  };

  const toggleDetail = () => {
    setShowTable("detail"); // State toggle karein
  };

  const toggleCreate = () => {
    dispatch(DataAction.ChangeMaintenanceCurrentStep(1))
    setShowTable("create"); // State toggle karein
  };

  const toggleTable = () => {
    setShowTable("table"); // State toggle karein
  };

  const [search, setSearch] = useState("")

  return (
    <div className="">
      {/* <SearchBar title={maintainances.title} desc={maintainances.description} onSubmit={openPopup}/>
      <LeaseTable /> */}
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div className="h-screen w-full">
                <div className="h-[10vh] mr-2">
                  <SearchBar
                    value={search}
                    onSearchChange={(e) => {
                      setSearch(e.target.value)

                    }}

                    onViewDetails={(id) => {
                      toggleDetail(id);
                    }}
                    title={maintainances.title}
                    desc={maintainances.description}
                    onSubmit={openPopup}
                  />
                </div>
                <div className="h-[90vh]">
                  <LeaseTable
                    search={search}
                    onViewDetails={(item) => {
                      setRecordId(item)
                      toggleDetail(item)
                    }}
                    onViewCreate={toggleCreate}
                  />
                </div>
              </div>
            );
          case "detail":
            return <DetailMain recordId={recordId._id} onBack={toggleTable} />;
          case "create":
            return <CreateMaintainace onBack={toggleTable} />;
          default:
            break;
        }
      })()}
      {showFilter && <FilterPopup onBack={closePopup} onFilter={closePopup} />}
    </div>
  );
};

export default MaintainanceSection;
