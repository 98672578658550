import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";
import moment from "moment";
import BaseUrl from "../../../../constants/BaseUrl";
import Myloader from "../../../reusebale/myLoader"
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import PropertySuccessPopup from "../../../popup/propertySuccess"
import { GetNotificationMessageByKey } from "../../../../db/NotificationMsgList";
import { useSelector } from "react-redux"
const Overview = ({ formField, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false);

  const closePopup = () => {
    setShowSuccess(false);
    onClose();
  };

  const { loginSuccess } = useSelector(
    (state) => state.auth
  );


  const CreateNotification = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "landlordId": loginSuccess.user._id,
      "title": GetNotificationMessageByKey("maintenance_creation").title,
      "description": `${GetNotificationMessageByKey("maintenance_creation").description} ${formField.propertyInfo.propertyName}`
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/notification/createNotification`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          // ShowSuccess("Notification Created Successfully")
        }
      })
      .catch((error) => console.error(error));
  }

  const CreateRequstHandler = (e) => {
    e.preventDefault();
    let callerInformation = {
      name: formField.callerName,
      number: formField.callerPhone,
      email: formField.callerEmail
    }
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "leaseId": formField.unitInfo.leaseId,
      "propertyId": formField?.propertyInfo?._id,
      "propertyInfo": formField?.propertyInfo,
      "unitInfo": formField.unitInfo,
      "description": formField.description,
      "requestCategory": formField.requestCategory,
      "requestType": 'formField.requestType',
      "requestDate": formField.requestDate,
      "dueDate": formField.requestDate,
      "proiority": "formField.proiority",
      "permissionToEnter": formField.permissionToEnter,
      "callerInformation": callerInformation,
      "techNote": formField.techNote,
      "accessNote": formField.accessNote,
      "imageList": formField.imageList
    });


    console.log(raw)

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BaseUrl}api/maintenence/createRequest`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          setLoading(false)
          setShowSuccess(true)
          CreateNotification()
          // alert("Please show a success popup and direct to home section and fix the loader")
        } else {
          setLoading(false)
          ShowError(result.message)
        }
      })
      .catch((error) => console.error(error));





  }
  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="request Overview"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
      </div>
      <div className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="w-[65%] mx-auto mt-2">
          <div className="flex justify-between">
            <div className=" w-[70%]">

              <div>
                <ParagraphTag
                  content="Property"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={formField?.propertyInfo?.propertyName}
                    classes={`text-darkColor/40 font-popinsRegular text-xs line-clamp-1`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">
              <div>
                <ParagraphTag
                  content="Unit"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={formField?.unitInfo?.unitName}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-2">
            <div className=" w-[70%]">
              <div>
                <ParagraphTag
                  content="Category"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={formField?.requestCategory?.category}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">

            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[70%]">
              <div>
                <ParagraphTag
                  content="Request Date"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={moment(new Date(formField?.requestDate)).format("YYYY-MM-DD")}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">
              <div>
                <ParagraphTag
                  content="Caller Name"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={formField?.callerName}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="flex justify-between mt-2">
            <div className=" w-[70%]">
              <div>
                <ParagraphTag
                  content="Caller Email"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={formField?.callerEmail}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">
              <div>
                <ParagraphTag
                  content="Caller Phone"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content={formField?.callerPhone}
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>



          <div className="mt-2">
            <ParagraphTag
              content="Description"
              classes={`text-darkColor font-popinsSemiBold text-sm`}
            />
            <div>
              <ParagraphTag
                content={formField?.description}
                classes={`text-darkColor/40 font-popinsRegular text-xs line-clamp-3`}
              />
            </div>
          </div>
          <div className="mt-2">
            <ParagraphTag
              content="Technician Note"
              classes={`text-darkColor font-popinsSemiBold text-sm`}
            />
            <div>
              <ParagraphTag
                content={formField?.techNote}
                classes={`text-darkColor/40 font-popinsRegular text-xs line-clamp-3`}
              />
            </div>
          </div>

          <div className="mt-2">
            <ParagraphTag
              content="Access Note"
              classes={`text-darkColor font-popinsSemiBold text-sm`}
            />
            <div>
              <ParagraphTag
                content={formField?.accessNote}
                classes={`text-darkColor/40 font-popinsRegular text-xs line-clamp-3`}
              />
            </div>
          </div>
          <div className="mt-4 flex">
            {
              formField?.imageList?.map((i) => {
                return (
                  <div className="w-[100px] h-[100px] mx-3 bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
                    <img
                      src={i?.imageUrl}

                    />
                    {/* <div className="border-2 border-dangerColor w-8 h-8 rounded-md flex justify-center items-center">
                  <Trash color="#E43B3B" size={18} />
                </div> */}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="h-[10%] flex justify-center">
        <div className="w-[40%] mx-auto">
          {
            loading ? <div className="flex justify-center items-center">
              <Myloader />
            </div> : <ButtonTag
              onSubmit={CreateRequstHandler}
              name="Submit"
              classes="text-base text-center bg-themeColor text-white"
            />
          }

        </div>
      </div>
      {showSuccess && <PropertySuccessPopup succMessage="Maintenance request created successfully" onClose={closePopup} />}
    </div>
  );
};

export default Overview;
