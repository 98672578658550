import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import ReactPaginate from "react-paginate";
import MapSection from "./map";
import NoData from "../../reusebale/noData";
import LeaseTableLoader from "../../skeleton-loader/lease-table";
import {
  ArrowUpDown,
  ArrowDownUp,
  Map,
  Plus,
  SquareCheck,
  SquareX,
  X,
  Pencil,
} from "lucide-react";
import db from "../../../db/index.json";
import * as PropertyAction from "../../../store/actions/property/PropertyAction";
import { useDispatch, useSelector } from "react-redux";

const PropertyTable = ({
  onViewDetails,
  onViewCreate,
  onViewUpdate,
  search,
}) => {
  const { allProperties } = useSelector((state) => state.property);
  const [data, setData] = useState([]);
  const { loginSuccess } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PropertyAction.GetAllPropertyByLanlord(loginSuccess?.user?._id));
    dispatch(PropertyAction.ChangePropertyCreateSteps(1));
  }, []);

  const ChangePropertyStatus = (id, status) => {
    const data = {
      id: id,
      status: status === "active" ? "inActive" : "active",
    };
    dispatch(PropertyAction.ChangePropertyStatusAction(data));
  };

  const { properties } = db;
  const [showMap, setShowMap] = useState(false);
  useEffect(() => {
    if (Array.isArray(allProperties)) {
      setData(allProperties);
      setLoading(false);
    } else {
      console.error("Expected `allProperties` to be an array:", allProperties);
      setData([]);
    }
  }, [allProperties]);
  const toggleMap = () => {
    setShowMap(!showMap); // Map visibility toggle
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const paginatedData =
    data?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage) ||
    [];

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "name", // Default sort key
    direction: "asc", // Default sort direction
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (typeof a[key] === "string" && typeof b[key] === "string") {
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
    });

    setData(sortedData);
  };

  return (
    <div className="flex justify-between h-full pt-5">
      <div className={`${showMap ? "w-[64%]" : "w-full"} h-full`}>
        <div className={`bg-white h-[90%]`}>
          <div className="w-[95%] mx-auto">
            <div className="flex justify-between py-5">
              <div className={`${showMap ? "w-[50%]" : "w-[70%]"}`}>
                <ParagraphTag
                  content={properties.heading}
                  classes="text-darkColor font-popinsSemiBold text-base"
                />
              </div>

              <div
                className={`flex justify-between ${
                  showMap ? "w-[50%]" : "w-[30%]"
                }`}
              >
                <button
                  className={`${
                    showMap ? "border-themeColor" : "border-[#6F6F6F]/70"
                  } border w-[48%] rounded-md flex items-center justify-center py-2`}
                  onClick={toggleMap}
                >
                  {showMap ? (
                    <X color="#FF814E" size={15} />
                  ) : (
                    <Map size={15} />
                  )}

                  <ParagraphTag
                    content={showMap ? properties.closeMap : properties.map}
                    classes={`${
                      showMap ? "text-themeColor" : "text-[#6F6F6F]"
                    } font-popinsSemiBold text-xs pl-2`}
                  />
                </button>
                <button
                  className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2"
                  onClick={onViewCreate}
                >
                  <Plus color="#FFFFFF" size={15} />
                  <ParagraphTag
                    content={properties.new}
                    classes="text-white font-popinsSemiBold text-xs pl-2"
                  />
                </button>
              </div>
            </div>
            <div className="overflow-x-auto w-full h-[400px] panelScroll mt-1">
              <table className="w-full ">
                <thead className="sticky top-0 bg-white z-10">
                  <tr className="border-b border-black/20">
                    <th className="w-[25%] text-left pb-2">
                      <span className="flex">
                        <ParagraphTag
                          content={properties.heading}
                          classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                        />
                        {sortConfig.key === "propertyName" ? (
                          sortConfig.direction === "asc" ? (
                            <ArrowUpDown
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("propertyName")}
                            />
                          ) : (
                            <ArrowDownUp
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("propertyName")}
                            />
                          )
                        ) : (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("propertyName")}
                          />
                        )}
                      </span>
                    </th>
                    <th className="w-[10%] pb-2">
                      <span className="flex justify-center">
                        <ParagraphTag
                          content={properties.status}
                          classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                        />
                        {sortConfig.key === "status" ? (
                          sortConfig.direction === "asc" ? (
                            <ArrowUpDown
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("status")}
                            />
                          ) : (
                            <ArrowDownUp
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("status")}
                            />
                          )
                        ) : (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        )}
                      </span>
                    </th>
                    {!showMap && (
                      <>
                        <th className="w-[10%] pb-2">
                          <span className="flex justify-center">
                            <ParagraphTag
                              content={properties.units}
                              classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                            />
                            {sortConfig.key === "unitCount" ? (
                              sortConfig.direction === "asc" ? (
                                <ArrowUpDown
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("unitCount")}
                                />
                              ) : (
                                <ArrowDownUp
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("unitCount")}
                                />
                              )
                            ) : (
                              <ArrowUpDown
                                className="cursor-pointer"
                                size={13}
                                color="#6F6F6F"
                                onClick={() => requestSort("unitCount")}
                              />
                            )}
                          </span>
                        </th>
                        <th className="w-[10%] pb-2">
                          <span className="flex justify-center">
                            <ParagraphTag
                              content={properties.leased}
                              classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                            />
                            {sortConfig.key === "leaseUnitCount" ? (
                              sortConfig.direction === "asc" ? (
                                <ArrowUpDown
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("leaseUnitCount")}
                                />
                              ) : (
                                <ArrowDownUp
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("leaseUnitCount")}
                                />
                              )
                            ) : (
                              <ArrowUpDown
                                className="cursor-pointer"
                                size={13}
                                color="#6F6F6F"
                                onClick={() => requestSort("leaseUnitCount")}
                              />
                            )}
                          </span>
                        </th>
                        <th className="w-[10%] pb-2">
                          <span className="flex justify-center">
                            <ParagraphTag
                              content={"State"}
                              classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                            />
                            {sortConfig.key === "state" ? (
                              sortConfig.direction === "asc" ? (
                                <ArrowUpDown
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("state")}
                                />
                              ) : (
                                <ArrowDownUp
                                  className="cursor-pointer"
                                  size={13}
                                  color="#6F6F6F"
                                  onClick={() => requestSort("state")}
                                />
                              )
                            ) : (
                              <ArrowUpDown
                                className="cursor-pointer"
                                size={13}
                                color="#6F6F6F"
                                onClick={() => requestSort("state")}
                              />
                            )}
                          </span>
                        </th>
                      </>
                    )}
                    <th className="w-[15%] pb-2">
                      <span className="flex justify-center">
                        <ParagraphTag
                          content={"Category (Type)"}
                          classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                        />
                        {sortConfig.key === "category" ? (
                          sortConfig.direction === "asc" ? (
                            <ArrowUpDown
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("category")}
                            />
                          ) : (
                            <ArrowDownUp
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("category")}
                            />
                          )
                        ) : (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("category")}
                          />
                        )}
                      </span>
                    </th>
                    <th className="w-[10%] pb-2">
                      <span className="flex justify-center">
                        <ParagraphTag
                          content={"Type"}
                          classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                        />
                        {sortConfig.key === "type" ? (
                          sortConfig.direction === "asc" ? (
                            <ArrowUpDown
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("type")}
                            />
                          ) : (
                            <ArrowDownUp
                              className="cursor-pointer"
                              size={13}
                              color="#6F6F6F"
                              onClick={() => requestSort("type")}
                            />
                          )
                        ) : (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("type")}
                          />
                        )}
                      </span>
                    </th>

                    <th className="w-[10%] text-right pr-4 pb-2">
                      <ParagraphTag
                        content={properties.actions}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {paginatedData?.map((item) => {
                    if (`${item?.propertyName}`.includes(search)) {
                      return (
                        <tr
                          key={item.id}
                          className="hover:bg-gray-100 rounded-md cursor-pointe"
                        >
                          <td className="py-2">
                            <div className="flex items-center">
                              <div className="">
                                <ImageTag
                                  path={item?.thumbnailImage?.url}
                                  classes="w-[50px] rounded-full h-[50px] object-cover"
                                  altText="logo"
                                />
                              </div>
                              <div
                                className=" cursor-pointer pl-3"
                                onClick={() => {
                                  onViewDetails(item._id);
                                }}
                              >
                                <ParagraphTag
                                  content={`PROP-${item?._id?.slice(1, 10)}`}
                                  classes="text-darkColor/50 font-popinsMedium text-xs"
                                />
                                <ParagraphTag
                                  content={item.propertyName}
                                  classes="text-darkColor font-popinsSemiBold text-sm"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="py-2 font-popinsMedium text-darkColor text-sm">
                            <span className="flex justify-center">
                              {item.status === "active" ? (
                                <SquareCheck color="#01CB78" size={20} />
                              ) : (
                                <SquareX color="#E43B3B" size={20} />
                              )}
                            </span>
                          </td>
                          {!showMap && (
                            <>
                              <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                                {item?.unitCount}
                              </td>
                              <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                                {item?.leaseUnitCount}
                              </td>
                              <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                                {`${item.state}`}
                              </td>
                            </>
                          )}
                          <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                            {`${item.category}`}
                          </td>
                          <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                            {`${item.type}`}
                          </td>

                          <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm">
                            <div className="flex justify-end">
                              <button
                                className="flex items-center px-2 py-1 border border-themeColor rounded-lg mr-1"
                                onClick={() => {
                                  onViewUpdate(item._id);
                                }}
                              >
                                <Pencil color="#0381CA" size={18} />
                              </button>
                              <label class="inline-flex items-center cursor-pointer relative pl-3">
                                <input
                                  checked={
                                    item.status === "active" ? true : false
                                  }
                                  value={true}
                                  onChange={() => {
                                    ChangePropertyStatus(item._id, item.status);
                                  }}
                                  type="checkbox"
                                  class="sr-only peer"
                                />
                                <div class="relative w-11 h-6 bg-[#E43B3B38] peer-focus:outline-none rounded-full peer dark:bg-[#E43B3B38] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#0381CA] after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#0381CA] after:border-[#0381CA] after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#0381CA] peer-checked:bg-[#E43B3B38]"></div>
                              </label>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
              {loading && <LeaseTableLoader />}
              {paginatedData.length === 0 && !loading && (
                <div className="w-full mt-5">
                  <NoData />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center h-[10%]">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            pageCount={Math.ceil(data.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName="flex space-x-2 items-center"
            pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
            previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
            nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
            disabledClassName="opacity-50 !cursor-not-allowed"
            activeClassName="bg-themeColor text-white"
            breakClassName="px-3 py-1 text-sm font-popinsMedium"
          />
        </div>
      </div>{" "}
      :
      {showMap && (
        <div className="w-[35%] py-5">
          <MapSection />
        </div>
      )}
    </div>
  );
};

export default PropertyTable;
