import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import ImageTag from "../../reusebale/imageTag";
import NoData from "../../reusebale/noData";
import { Phone, ArrowRight } from "lucide-react";

const BalancesSection = () => {
  const { rightPanel } = db;

  return (
    <div className="bg-white rounded-2xl py-5 px-4 w-full mt-5 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="flex justify-between items-center ">
        <ParagraphTag
          content={rightPanel.balance}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={rightPanel.viewall}
          classes="text-themeColor font-popinsSemiBold text-sm cursor-pointer"
        />
      </div>
      <div className="flex border-b-[1px] border-darkColor/40 pb-1 mt-6">
        <div className="w-[50%]">
          <ParagraphTag
            content={rightPanel.tenants}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[25%] text-center ">
          <ParagraphTag
            content={rightPanel.rent}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[25%] text-end">
          <ParagraphTag
            content={rightPanel.quick}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
      </div>
      <div className="max-h-[230px] overflow-y-scroll panelScroll">
        {rightPanel.balanceTable.map((item, i) => (
          <div className="flex mt-4 justify-between">
            <div className="w-[49%] flex items-center">
              <div className="w-[16%]">
                <ImageTag
                  path="/assets/daashboard/balance.png"
                  classes="w-full"
                  altText="login"
                />
              </div>
              <div className="w-[84%] pl-3">
                <ParagraphTag
                  content={item.rowDesc}
                  classes="text-darkColor/40 font-popinsMedium text-xs"
                />
                <ParagraphTag
                  content={item.rowName}
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
            <div className="w-[29%] flex items-center justify-center">
              <ParagraphTag
                content={item.price}
                classes="text-darkColor font-popinsMedium text-sm pl-2"
              />
            </div>
            <div className="w-[19%] flex items-center">
              <div className="flex justify-between items-center w-[85%] mx-auto">
                <div className="flex justify-center items-center bg-white border-2 border-themeColor w-[45%] h-8 rounded-lg cursor-pointer">
                  <Phone color="#0381CA" size={18} fill="#0381CA" />
                </div>
                <div className="flex justify-center items-center bg-themeColor w-[45%] h-8 rounded-lg cursor-pointer">
                  <ArrowRight color="#FFFF" size={18} />
                </div>
              </div>
            </div>
          </div>
        ))}

        {rightPanel.balanceTable.length === 0 && (
          <div className="w-full mt-5 flex justify-center">
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default BalancesSection;
